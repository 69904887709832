import styled from 'styled-components';

import Dog from '../Dog';
import { Text, Title } from '../SmallComponents';

export const Wrapper = styled.div`
    text-align: center;
    background: #F5F5F5;
    width: 100%;
    height: 100%;
`;

export const LinkText = styled.a`
    text-align: center;
`;

export const Subtitle = styled.div`
    font-weight: 500;
`;

export const SampleDescription = styled.div`
    text-align: center;
`;

export const PageContent = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: 100%;
`;

export const FlareWrapper = styled.div`
    width: 50%;
    margin: auto;
    padding: 24px;
    background-color: #dcd2d2;
    border-radius: 10px;
`;

export const TextWrapper = styled.div`
    width: 45%;
    padding-left: 40px;
    @media screen and (max-width: 480px) {
        width: 80%;
        padding-right: 40px;
    }
    @media screen and (max-width: 800px) {
        width: 90%;
        order: 1;
    }
`;

export const TitleWrapper = styled.div`
    text-align: left;
    display: flex;
    @media screen and (max-width: 800px) {
        margin: 0;
    }
`;

export const StyledText = styled(Text)`
    @media screen and (max-width: 800px) {
        margin: 0;
    }
`

export const StyledDog = styled(Dog)`
    @media screen and (max-width: 480px) {
        order: 0;
    }
    @media screen and (max-width: 800px) {
        order: 0;
    }
`;

export const StyledTitle = styled(Title)`
    @media screen and (max-width: 800px) {
        padding: 0 0 16px 0;
        text-align: center;
        margin: 0;
    }
`;

export const ActionButton = styled.button`
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
    border-radius: 10px;
    border-width: 0;
    width: 30%;
    font-weight: 500;
    font-size: 24px;
    color: white;
    padding: 16px;
    margin: 0 8px;
    background-color: ${props => props.color};
    &:hover {
        text-decoration: underline;
        cursor: pointer;
    }
    @media screen and (max-width: 500px) {
        width: 100%;
        margin: 8px;
    }
`;

export const ButtonWrapper = styled.div`
    @media screen and (max-width: 500px) {
        display: flex;
        flex-direction: column;
    }
`;