import React, { PureComponent } from 'react';
import moment from 'moment';


import DeleteWorksheet from '../DeleteWorksheet';
import { Spacer, TableTitle, StyledButton, StyledTable, StyledRow } from '../SmallComponents';

export default class WorksheetTable extends PureComponent {

    state = {
        showWSUploader: false,
    };

    showWSUploader = () => {
        const { showWSUploader } = this.state;
        this.setState({ showWSUploader: !showWSUploader, showWBUploader: false });
    };

    fileListParser = (worksheets) => {
        const { getWorksheets } = this.props;
        const tableContents = worksheets.map((worksheet) =>
            <StyledRow>
                <td>{worksheet.name}</td>
                <td>{worksheet.file_name}</td>
                <td>{worksheet.download_name}</td>
                <td>{moment(worksheet.date_uploaded).format('MM/D/YYYY')}</td>
                {worksheet.pricing ? <td>{worksheet.pricing}</td> : null}
                <td>{worksheet.url ? <a href={`https://${worksheet.url}`}>link</a>: "No link provided"}</td>
                <td>{worksheet.download_count}</td>
                <td><DeleteWorksheet getWorksheets={getWorksheets} worksheet={worksheet} /></td>
            </StyledRow>
        )
        return tableContents;
    };

    render() {
        const { worksheets, fileUploader } = this.props;
        const { showWSUploader } = this.state;
        return(
            <div>
                <TableTitle>Sample Kits</TableTitle>
                <Spacer />
                <StyledTable>
                    <tr>
                        <th>Sample Kit Name</th>
                        <th>Display Name</th>
                        <th>File Name</th>
                        <th>Date Uploaded</th>
                        <th>Video Link</th>
                        <th>Downloads</th>
                        <th>Remove?</th>
                    </tr>
                    {worksheets && worksheets.length > 0 && this.fileListParser(worksheets)}
                </StyledTable>
                <Spacer />
                <StyledButton add onClick={this.showWSUploader}>{showWSUploader ? "Hide" : "Show"} Sample Kit Uploader</StyledButton>
                {showWSUploader && fileUploader()}
            </div>
        )
    };
}