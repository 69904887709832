import React from 'react';
import moment from 'moment';

import DeleteCustomerButton from '../DeleteCustomerButton';
import { Spacer, StyledRow, StyledTable, TableTitle } from '../SmallComponents';

import { StyledButton, Wrapper } from './styled';

export default function SalesLeadTable(props) {

    const [sortConfig, setSortConfig] = React.useState({ key: null, direction: null });

    let sortedSalesLeads =  [...props.salesLeads]

    const sortedItems = React.useMemo(() => {

        const itemSorter = (a, b) => {
            const item_a = sortConfig.key === 'creation_date'
                ? new Date(a[sortConfig.key])
                : a[sortConfig.key]
            const item_b = sortConfig.key === 'creation_date'
                ? new Date(b[sortConfig.key])
                : b[sortConfig.key]
            if (item_a < item_b) {
                return sortConfig.direction === 'ascending' ? -1 : 1;
            }
            if (item_a > item_b) {
                return sortConfig.direction === 'ascending' ? 1 : -1;
            }
            return 0
         }
        sortedSalesLeads.sort(itemSorter);
        return sortedSalesLeads;
    }, [props.salesLeads, sortConfig]);

    function handleClick(fieldName) {
        requestSort(fieldName);
    }

    const requestSort = key => {
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
          direction = 'descending';
        }
        setSortConfig({ key, direction });
    }

    function salesLeadParser() {
        const { getSalesLead } = props;
        const tableContents = sortedItems.map((salesLead) =>
            <StyledRow>
                <td>
                    {salesLead.name}
                </td>
                <td>
                    {salesLead.email}
                </td>
                <td>
                    {salesLead.address}
                </td>
                <td>
                    {salesLead.institution_name}
                </td>
                <td>
                    {moment(salesLead.creation_date).format("MM/D/YYYY")}
                </td>
                <td>
                    {salesLead.comment}
                </td>
                <td>
                    <DeleteCustomerButton
                        salesLeadId={salesLead.id}
                        getSalesLeads={getSalesLead}
                    />
                </td>
            </StyledRow>
        )
        return tableContents
    };

    return(
        <Wrapper>
            <TableTitle>Sales Leads</TableTitle>
            <Spacer height={4} />
            <StyledTable>
                <tr>
                    <th onClick={() => handleClick('name')}>
                        Name
                    </th>
                    <th onClick={() => handleClick('email')}>
                        Email
                    </th>
                    <th onClick={() => handleClick('address')}>
                        Address
                    </th>
                    <th onClick={() => handleClick('institution_name')}>
                        School Name
                    </th>
                    <th onClick={() => handleClick('creation_date')}>
                        Date Submitted
                    </th>
                    <th onClick={() => handleClick('comment')}>
                        Comment
                    </th>
                    <th>
                        Remove?
                    </th>
                </tr>
                {salesLeadParser()}
            </StyledTable>
            <Spacer />
            <a href="/api/customer_table"><StyledButton>
                Download Sales Leads
            </StyledButton></a>
        </Wrapper>
    )
}