import React, { PureComponent } from 'react';
import { Redirect, withRouter } from 'react-router-dom'
import axios from 'axios';

import { STRIPE_PUBLIC_KEY } from '../Secrets';
import { loadStripe } from '@stripe/stripe-js';
import { Elements} from '@stripe/react-stripe-js';

import CCErrorModal from '../CCErrorModal';
import CheckoutBox from '../CheckoutBox';
import NavBar from '../NavBar';
import TCModal from '../TCModal';
import { RoundedBox, StyledIcon, Subtitle, Title, StyledLink } from '../SmallComponents';

import {  BoldText, ContentWrapper, ErrorAlert, HelperText, StyledLabel, NameWrapper, StyledText, StyledTable, StyledTh, FormInput } from './styled';

const stripePromise = loadStripe(STRIPE_PUBLIC_KEY)

const validEmailRegex =
RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);

// const validNameRegex =
// RegExp(/^(([A-Za-z]+[\-\']?)*([A-Za-z]+)?\s)+([A-Za-z]+[\-\']?)*([A-Za-z]+)?$/);

// const validOrganizationNameRegex =
// RegExp(/^[.@&]?[a-zA-Z0-9 ]+[ !.@&()]?[ a-zA-Z0-9!()]+/);


class CheckoutForm extends PureComponent {

    state = {
      address: '',
      allBoxesFilled: false,
      clientSecret: '',
      email: '',
      errors: {
        email: '',
        matchingEmail: '',
        organizationName: '',
        teacherName: '',
      },
      matchingEmail: '',
      orderNumber: '',
      organizationName: '',
      paymentIntentId: '',
      poNumber: '',
      redirectToThankYou: false,
      showErrorModal: false,
      showPOText: false,
      stripeButtonDisabled: false,
      tcModalOpen: false,
      teacherName: '',
    }

    componentDidMount() {
      const data = { total: this.props.location.state.cartTotal };
      axios({
        url: '/api/begin_purchase',
        method: 'POST',
        data
      })
      .then((res) => {
        this.setState({ clientSecret: res.data.client_secret, orderNumber: res.data.order_number, paymentIntentId: res.data.payment_intent_id  })
      })
    }

    updatePurchaseRecord = () => {
      const { email, firstName, lastName, address, teacherName, organizationName, paymentIntentId, poNumber } = this.state;
      const { cartItems } = this.props.location.state;
      const data = { cartItems, email, firstName, lastName, address, teacherName, organizationName, paymentIntentId, poNumber };
      axios({
        url: '/api/update_purchase',
        method: 'POST',
        data
      })
      .catch(err => {
        console.log(err);
        // open error modal
      })
    }


    displayItems = () => {
      const { cartItems } = this.props.location.state;
      if (cartItems) {
        const items = cartItems.map(item => (
          <tr><td>{item.name}</td><td>${item.price}</td><td>{item.qty}</td></tr>
        ))
        return items
      }
      return <div>Add items to your shopping cart <a href="/shop">here</a>.</div>
    }


    handleChange = (event) => {
      event.preventDefault();
      const { name, value } = event.target;
      let errors = this.state.errors;

      switch (name) {
        case 'teacherName':
          errors.teacherName =
            (value.length < 2)
              ? "Please enter the teacher's name, limit 30 characters."
              : errors.teacherName
          errors.teacherName =
            (value.length > 30)
              ? "Limit 30 characters. Please abbreviate if needed."
              : errors.teacherName
          errors.teacherName =
            /\d/.test(value)
              ? "Invalid entry. Please remove numbers."
              : errors.teacherName
          if (value.length > 2 && value.length < 30 && !/\d/.test(value)) {
            errors.teacherName = ''
          }
          break;
        case 'email':
          errors.email =
            validEmailRegex.test(value)
              ? ''
              : 'Email is not valid.';
            if (this.state.teacherName.length < 2) {
              errors.teacherName = "Please fill out required box."
            }
            if (this.state.organizationName.length < 2) {
              errors.organizationName = "Please fill out required box."
            }
          errors.matchingEmail =
            value !== this.state.matchingEmail
              ? 'Emails do not match.'
              : ''
              break;
        case 'organizationName':
          errors.organizationName =
            (value.length < 2)
              ? 'Please enter the organization name, limit 30 characters.'
              : errors.organizationName
          errors.organizationName =
            (value.length > 30)
              ? 'Limit 30 characters. Please abbreviate if needed.'
              : errors.organizationName
            if (this.state.teacherName.length === 0) {
              errors.teacherName = "Please fill out required box."
            }
            if (value.length > 2 && value.length < 30) {
              errors.organizationName = ''
            }
              break;
        case 'matchingEmail':
          errors.matchingEmail =
            value !== this.state.email
              ? 'Emails do not match.'
              : ''
            break;
        default:
          break;
      }
      this.setState({ errors, [name]: value } )
    }

    validateForm = () => {
      let valid = true;
      const inputList = ['teacherName', 'organizationName', 'email', 'matchingEmail'];
      let errors = this.state.errors;
      inputList.forEach(element => {
        if (this.state[element].length === 0) {
          errors[element] = "Please fill out required boxes."
        }
        this.setState({ errors });
      });
      Object.values(errors).forEach(
        // if we have an error string set valid to false
        (val) => val.length > 0 && (valid = false)
      );
      return valid;
    }

    poNumberUpdate = (evt) => {
      this.setState({ poNumber: evt.target.value });
    }

    showErrorModal = () => {
      this.setState({ showErrorModal: true });
    }

    closeModal = () => {
      this.setState({ showErrorModal: false, stripeButtonDisabled: false });
    }

    redirectState = () => {
      this.setState({ redirectToThankYou: true });
    }

    renderRedirect = () => {
      const { email, organizationName, orderNumber, poNumber } = this.state;
      const { cartItems, cartTotal } = this.props.location.state;
      if (this.state.redirectToThankYou) {
          return <Redirect to={{
            pathname: '/thankyou',
            state: { cartItems, email, organizationName, cartTotal, orderNumber, poNumber }
            }}
            />
      }
    }

    disableStripeButton = () => {
      this.setState({ stripeButtonDisabled: true });
    }

    showPOTextFunc = () => {
      const { showPOText } = this.state;
      this.setState({ showPOText: !showPOText });
    }

    openTCModal = () => {
        this.setState({ tcModalOpen: true });
    }

    closeTCModal = () => {
      this.setState({ tcModalOpen: false });
    }

    render() {
      const { clientSecret, errors, stripeButtonDisabled, email, matchingEmail, showErrorModal, showPOText, tcModalOpen } = this.state;
      const { cartItems } = this.props.location.state;
      const itemsExist = (cartItems || localStorage.EasyCart);
      if (!itemsExist) {
        return <Redirect to="/shop" />
      }
      return (
          <Elements stripe={stripePromise}>
              {this.renderRedirect()}
              {showErrorModal && <CCErrorModal visible={showErrorModal} closeModal={this.closeModal}/>}
              {tcModalOpen && <TCModal visible={tcModalOpen} closeModal={this.closeTCModal} />}
              <NavBar />
              <ContentWrapper>
                  <Title>Checkout Form</Title>
                  <Subtitle>Your Items</Subtitle>
                  <form autocomplete="off">
                    <RoundedBox>
                    <StyledTable>
                      <tr>
                        <StyledTh>Product</StyledTh><StyledTh>Price</StyledTh><StyledTh>Qty</StyledTh>
                      </tr>
                      {this.displayItems()}
                    </StyledTable>
                    <StyledText><BoldText>Total:</BoldText> ${this.props.location.state.cartTotal}</StyledText>
                    </RoundedBox>
                    <Subtitle>Your Order Information</Subtitle>
                    <RoundedBox>
                      <NameWrapper>
                        <StyledLabel for="teacherName">*** Teacher Name:</StyledLabel>
                        <FormInput
                          name="teacherName"
                          type="text"
                          onChange={this.handleChange}
                          value={this.state.teacherName}
                          placeholder="Teacher or Instructor Name"
                          required
                        ></FormInput>
                        <ErrorAlert error={errors.teacherName}><StyledIcon className="fas fa-exclamation"></StyledIcon>{errors.teacherName}</ErrorAlert>
                      </NameWrapper>
                      <NameWrapper stacked>
                        <StyledLabel stacked for="organizationName">*** School / District / Company / Individual name:</StyledLabel>
                        <FormInput stacked
                          name="organizationName"
                          type="text"
                          onChange={this.handleChange}
                          value={this.state.organizationName}
                          placeholder="School/District/Company/Individual name"
                          required
                        ></FormInput>
                        <ErrorAlert error={errors.organizationName}><StyledIcon className="fas fa-exclamation"></StyledIcon>{errors.organizationName}</ErrorAlert>
                      </NameWrapper>
                    <NameWrapper>
                      <StyledLabel for="poNumber">Your Purchase Order Reference: <StyledIcon
                        className="fas fa-question-circle"
                        onClick={this.showPOTextFunc}
                      >
                      </StyledIcon>
                      </StyledLabel>
                      <FormInput
                        name="poNumber"
                        type="text"
                        onChange={this.handleChange}
                        placeholder="(optional) Purchase Order Number"
                        value={this.state.poNumber}
                      ></FormInput>
                    </NameWrapper>
                    {showPOText &&<HelperText>
                          (optional) Include a purchase order number if you need one for your organization's records.
                    </HelperText>}
                    </RoundedBox>
                    <Subtitle>Purchaser Information</Subtitle>
                    <RoundedBox>
                    <NameWrapper>
                      <StyledLabel for="email">
                        ***Email address:
                      </StyledLabel>
                      <FormInput
                        name="email"
                        onChange={this.handleChange}
                        type="email"
                        value={email}
                        placeholder="Email Address"
                        title="Invalid email."
                        required
                      >
                      </FormInput>
                      <ErrorAlert error={errors.email}><StyledIcon className="fas fa-exclamation"></StyledIcon>{errors.email}</ErrorAlert>
                    </NameWrapper>
                    <NameWrapper>
                      <StyledLabel for="matchingEmail">
                        ***Confirm email address:
                      </StyledLabel>
                      <FormInput
                        name="matchingEmail"
                        onChange={this.handleChange}
                        type="email"
                        value={matchingEmail}
                        placeholder="Confirm Email Address"
                        title="Invalid email."
                        required
                      >
                      </FormInput>
                      <ErrorAlert error={errors.matchingEmail}><StyledIcon className="fas fa-exclamation"></StyledIcon>{errors.matchingEmail}</ErrorAlert>
                    </NameWrapper>
                    (You will receive an email at this address with instructions how to access the files you have ordered.)
                    </RoundedBox>
                    <RoundedBox>
                      <CheckoutBox
                        clientSecret={clientSecret}
                        disableStripeButton={this.disableStripeButton}
                        email={email}
                        boxValidation={this.validateForm}
                        redirectState={this.redirectState}
                        showErrorModal={this.showErrorModal}
                        stripeButtonDisabled={stripeButtonDisabled}
                        updatePurchaseRecord={this.updatePurchaseRecord}
                      />
                      <NameWrapper centered>
                        <StyledLabel centered>
                          By clicking confirm order, I agree to the <StyledLink onClick={this.openTCModal}>Terms and Conditions</StyledLink> of sale.
                        </StyledLabel>
                      </NameWrapper>
                    </RoundedBox>
                  </form>
              </ContentWrapper>
          </Elements>
      )
    }
  }

export default withRouter(CheckoutForm);