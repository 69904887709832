import React from 'react';

import {
    BigCardText,
    DescriptionWrapper,
    VideoPic,
    PicWrapper,
    OuterWrapper,
    SmallCardText,
    StyledLink
} from './styled';

export default function VideoPlaylistItem(props) {

    const { video, selectVideo } = props;

    function handleClick(e) {
        e.preventDefault();
        selectVideo(video);
    }

    return(
        <StyledLink
            to={{
                path: 'playvideo',
                search: `video=${video.id}`
            }}
                onClick={handleClick}
        >
            <OuterWrapper>
                <PicWrapper>
                    <VideoPic
                        src={`${process.env.PUBLIC_URL}/video_frames/${video.id}.png`}
                    />
                </PicWrapper>
                <DescriptionWrapper>
                    <BigCardText>
                        <div style={{display: "block", width: "100%"}}>
                            {`Video #${video.number}`}
                        </div>
                        <div>
                            {`${video.title}:`}
                        </div>
                        <div style={{'font-weight': '200'}}>{`${video.description}`}</div>
                    </BigCardText>
                    <SmallCardText>
                        <div style={{padding: '16px'}}>{`${video.number}. ${video.title}`}</div>
                        <div style={{'font-weight': '300', padding: '0 16px'}}>{`${video.description}`}</div>
                    </SmallCardText>
                </DescriptionWrapper>
            </OuterWrapper>
        </StyledLink>
    )
}