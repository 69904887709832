import React, { PureComponent } from 'react';

import axios from 'axios';
import {
    BrowserRouter as Router,
    Redirect,
    Route,
    Switch,
} from "react-router-dom";

import AdminPage from '../AdminPage';
import CheckoutForm from '../CheckoutForm';
import DownloadPage from '../DownloadPage';
import NavBar from '../NavBar';
import PurchaseDownloadPage from '../PurchaseDownloadPage';
import ShopPage from '../ShopPage';
import ThankYouPage from '../ThankYouPage';
import StudentPortal from '../StudentPortal';
import UserLogin from '../UserLogin';
import UserRegistration from '../UserRegistration';

import {
    ButtonWrapper,
    Wrapper,
    PageContent,
    TextWrapper,
    StyledDog,
    StyledText,
    StyledTitle,
    ActionButton,
} from './styled';

import { Spacer } from '../SmallComponents';

export default class MainPage extends PureComponent {
    state = {
        email: "",
        adminAccess: false,
        loggedIn: false,
        // teacherAccess: false,
        codeMessage: "",
        password: "",
        code: "",
        loginErrorMsg: "",
    };

    componentDidMount() {
        if (localStorage.easyWeb) {
            const token = localStorage.easyWeb;
            this.tokenCheck(token);
        }
        return
    }

    logOut = () => {
        this.setState({
            adminAccess: false,
            loggedIn: false,
            redirect: "/login"
        });
        localStorage.removeItem('easyWeb');
    }

    tokenCheck = (token) => {
        const data = token;
        axios({
            url: '/api/access_check',
            method: 'POST',
            headers: {'content-type': 'text/json'},
            data
        })
        .then(res => {
            if (res.status === 200) {
                const accessGranted = res.data.username || res.data.classcode
                this.setState({ adminAccess: res.data.admin });
                this.setState({ loggedIn: accessGranted });
            }
        })
        .catch(err => {
            console.log(err);
            return null;
        });
    }

    handleEmailFieldChange = (event) => {
        this.setState({ email: event.target.value});
    }

    handleCodeChange = (event) => {
        this.setState({ code: event.target.value})
    }

    handlePasswordFieldChange = (event) => {
        this.setState({ password: event.target.value });
    }

    loginWithCode = (event) => {
        event.preventDefault();
        const { code } = this.state;
        const data = { code };
        axios({
            url: '/api/login',
            method: 'POST',
            data
        })
        .then(res => {
            if (res.data.token) {
                localStorage.setItem("easyWeb", JSON.stringify(res.data));
                this.setState({
                    loggedIn: true,
                    adminAccess: false
                });
            }
            return res.data
        })
        .catch(err => {
            console.log(err);
            this.setState({
                codeMessage: "Incorrect code. Please try again."
            })
            return null;
        });
    }

    attemptLogin = (event) => {
        event.preventDefault();
        const { email, password } = this.state;
        const data = { email, password };
        axios({
            url: '/api/login',
            method: 'POST',
            data
        })
        .then(res => {
            if (res.data.token) {
                localStorage.setItem("easyWeb", JSON.stringify(res.data));
                this.setState({
                    loggedIn: true,
                    adminAccess: res.data.admin
                });
            }
            return res.data
        })
        .catch(err => {
            console.log(err);
            this.setState({
                loginErrorMsg: "Incorrect login details. Please try again."
            });
            return null;
        });
    };

    render() {
        const { adminAccess, loggedIn, password, email, codeMessage, code, loginErrorMsg } = this.state;
        return(
            <Router>
                <Switch>
                    <Route path="/samples">
                        <DownloadPage />
                    </Route>
                    <Route path="/cart" exact={true}>
                        <Homepage />
                    </Route>
                    <Route path="/shop" exact={true}>
                        <ShopPage />
                    </Route>
                    <Route path="/admin">
                        <AdminPage
                            logOut={this.logOut}
                            loggedIn={adminAccess}
                        />
                    </Route>
                    <Route path="/login">
                        <UserLogin
                            email={email}
                            handleEmailFieldChange={this.handleEmailFieldChange}
                            handlePasswordFieldChange={this.handlePasswordFieldChange}
                            handleSubmit={this.attemptLogin}
                            loginWithCode={this.loginWithCode}
                            codeMessage={codeMessage}
                            loginErrorMsg={loginErrorMsg}
                            password={password}
                            handleCodeChange={this.handleCodeChange}
                            code={code}
                            loggedIn={loggedIn}
                        />
                    </Route>
                    <Route path="/register">
                        <UserRegistration />
                    </Route>
                    <Route path="/thankyou">
                        <ThankYouPage />
                    </Route>
                    <Route path="/checkout">
                        <CheckoutForm />
                    </Route>
                    <Route path="/admin_login">
                        <Redirect to='/login' />
                    </Route>
                    <Route path="/about">
                        <Homepage />
                    </Route>
                    <Route path="/yourdownload">
                        <PurchaseDownloadPage />
                    </Route>
                    <Route path="/playvideo">
                        <StudentPortal
                            loggedIn={loggedIn}
                            logout={this.logOut}
                        />
                    </Route>
                    <Route path="/">
                        <DownloadPage />
                    </Route>
                    <Route path='*' exact={true}>
                        <DownloadPage />
                    </Route>
                </Switch>
            </Router>
        );
    }
}

function Homepage() {
    return (
        <Wrapper>
            <NavBar />
            <PageContent>
                <TextWrapper>
                    <StyledTitle left>Welcome to Mr. Tandy’s ESL Class</StyledTitle>
                    <StyledText left>
                        Mr. Tandy’s ESL Class features "EASY, the ESL Series" – videos for teens and adults who want to learn real-world American English and have fun as they learn.
                    </StyledText>
                    <Spacer />
                    <StyledText left>
                        There are 12 videos – over 700 minutes of instruction and practice - and more than 450 worksheets providing more content, instruction, practice and tests.
                    </StyledText>
                    <Spacer />
                    <StyledText left>
                        <strong>Teachers: </strong> Students can learn and practice everywhere – in class, at home, in libraries, on computers, on their phones*, 24/7, inside, outside – anywhere they get the internet.
                    </StyledText>
                    <Spacer />
                    <StyledText left>
                        <strong>Individual Students: </strong> Join Mr. Tandy’s ESL Class! Watch our videos and study ESL 24/7 – at home, everywhere. Practice with the worksheets.
                    </StyledText>
                    <Spacer />
                    <StyledText left>
                        There are 2 levels:
                    </StyledText>
                    <Spacer />
                    <StyledText left>
                        Videos 1 – 6 for the Beginner student
                    </StyledText>
                    <Spacer />
                    <StyledText left>
                        Videos 7 – 12 for High Beginner & Intermediate students
                    </StyledText>
	    	<Spacer />
                    <StyledText left>            
	    Download the <a href='/ScopeAndSequence.pdf' target="_blank">Scope and Sequence</a> to see the video descriptions.
                    </StyledText>
                    <Spacer />
                    <ButtonWrapper>
                        <a href="/shop">
                            <ActionButton
                                color="#ED474B"
                            >
                                Buy Workbooks
                            </ActionButton>
                        </a>
	    		<a href="https://www.youtube.com/watch?v=baBgsZ0PRAg&list=PLR7_EiINpRQb11gL43bGb6XEcYmApUZ5I">
	    		   <ActionButton
	    			color="#367DC0"
	    		   >
	    		        Watch the Videos
	    	           </ActionButton>
	                </a>
                    </ButtonWrapper>
                    {/* <StyledText>
                        Our videos run on most Android phones, iPhones and iPads. Data charges may be incurred.
                    </StyledText> */}
                </TextWrapper>
                <StyledDog />
            </PageContent>
            <Spacer />
        </Wrapper>
    )
}
