import React, { PureComponent } from 'react';

import { GreenCheckMark, WorkbookPurchaseButton } from './styled';

export default class PurchaseButton extends PureComponent {

    handleClick = (event) => {
        const { addToCart, workbook } = this.props;
        event.preventDefault();
        addToCart(workbook);
    }

    itemInCart = () => {
        const { itemsInCart, workbook } = this.props;
        if (itemsInCart.includes(workbook.id)) {
            return(
                <div>
                    <GreenCheckMark className="fas fa-check"></GreenCheckMark>
                    Added to cart
                </div>
            )
        }
        return(
            <WorkbookPurchaseButton
                onClick={this.handleClick}
                workbook={workbook}
            >
                Add to Cart
            </WorkbookPurchaseButton>
        )
    }

    render() {
        return(
            <div>
                {this.itemInCart()}
            </div>
        )
    }
}