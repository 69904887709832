import styled, { css } from 'styled-components';

import Logo from '../Logo';
import { StyledIcon } from '../SmallComponents';

export const NavBarWrapper = styled.div`
    height: auto;
    background-color: #4D9B56;
    text-align: left;
    width: 100%;
    display: flex;
`;

export const CenteringWrapper = styled.div`
    height: 80px;
    line-height: 80px;
    display: inline-block;
    text-align: center;
    margin-top: 32px;
    border-radius:20px 20px 0 0;
    ${props => !props.outline && css`
        border: solid;
        border-color: ${props.color};
    `}
    ${props => props.outline && css`
        border-style: solid;
        border-color: ${props.color};
    `};
    ${props => props.color && css`
        background-color: ${props.color};
    `}
    ${props => props.widthCalc && css`
        width: calc(90% / ${props.widthCalc});
    `};
    ${props => props.admin && props.widthCalc && css`
        width: calc(90% / (${props.widthCalc} + 1 ));
    `};
    @media screen and (max-width: 800px) {
        width: 100%;
        display: block;
        padding: 8px;
        margin-top: 0px;
        background-color: #4D9B57;
        border-color: #4D9B57;
        height: auto;
        line-height: 0;
    }
`;

export const NavItem = styled.div`
    text-decoration: none;
    font-weight: 500;
    font-size: 20px;
    color: white;
    padding: 0 8px;
    line-height: 1.5;
    display: inline-block;
    vertical-align: middle;
`;

export const ColorBar = styled.div`
    width: 100%;
    height: 16px;
    ${props => props.color && css`
        background-color: ${props.color};
    `}
    display: block;
`

export const NavItemWrapper = styled.div`
    display: inline-block;
    width: 100%;
    text-align: end;
    @media screen and (max-width: 800px) {
        display: none;
    }
`;

export const StyledLogo = styled(Logo)`
    width: 20%;
    height: auto;
    display: inline-block;
    @media screen and (max-width: 800px) {
        width: 70%;
    }
`;

export const Hamburger = styled(StyledIcon)`
    display: none;
    @media screen and (max-width: 800px) {
        width: 50%;
        display: inline-block;
        color: white;
        font-size: 40px;
        padding: 8px;
        text-align: right;
    }
`;

export const MobileNavBar = styled.div`
    display: none;
    @media screen and (max-width: 800px) {
        display: ${props => props.visible ? 'block' : 'none' };
        padding: 20px;
        background-color: #4D9B56;
    }
`;