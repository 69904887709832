import React, { useState, useEffect } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import {
    Redirect,
} from "react-router-dom";

import ClassroomNavBar from '../ClassroomNavBar';
import Copyright from '../Copyright';

import { useStyles } from './styled';

export default function UserLogin(props) {

  const classes = useStyles();

  const [redirect, setRedirect] = useState(null)

    const {
        handleSubmit,
        handleEmailFieldChange,
        handlePasswordFieldChange,
        handleCodeChange,
        code,
        email,
        password,
        loginWithCode,
        loggedIn,
        codeMessage,
        loginErrorMsg,
    } = props;

    useEffect(() => {
        if (loggedIn != undefined) {
            setRedirect(loggedIn)
        }
    }, [loggedIn])

  if (redirect) {
    return <Redirect to='/playvideo?video=unit_1' />
  } else {
    return (
        <div>
            <ClassroomNavBar />
            <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <Avatar className={classes.avatar}>
                <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                Sign in
                </Typography>
                <form className={classes.form} noValidate>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="classroom-code"
                        label="Classroom Code"
                        name="classroom-code"
                        autoComplete="XXXXX"
                        onChange={handleCodeChange}
                        autoFocus
                        value={code}
                    />
                    <div style={{color: "#F60057", 'text-align': "center"}}>{codeMessage}</div>

                    <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        onClick={loginWithCode}
                        disabled={( code && code.length > 4 ) ? false : true }
                    >
                        Access Classroom
                    </Button>
                </form>
                <form className={classes.form} noValidate onSubmit={handleSubmit}>
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                    autoFocus
                    onChange={handleEmailFieldChange}
                    value={email}
                />
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type="password"
                    id="password"
                    autoComplete="current-password"
                    onChange={handlePasswordFieldChange}
                    value={password}
                />
                {/* Set token for more than just session */}
                {/* <FormControlLabel
                    control={<Checkbox value="remember" color="primary" />}
                    label="Remember me"
                /> */}
                <div style={{color: "#F60057", 'text-align': "center"}}>{loginErrorMsg}</div>
                <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                    disabled={ (password && email) ? false : true }
                    onClick={handleSubmit}
                >
                    Sign In
                </Button>
                <Grid container>
                    <Grid item xs>
                    <Link href="#" variant="body2">
                        Forgot password?
                    </Link>
                    </Grid>
                    <Grid item>
                    <Link href="/shop" variant="body2">
                        {"Purchase subscription"}
                    </Link>
                    </Grid>
                </Grid>
                </form>
            </div>
            <Box mt={8}>
                <Copyright />
            </Box>
            </Container>
        </div>
      );
    }
  }