import React from 'react';

import { NavBarWrapper } from '../NavBar/styled';
import { StylesProvider } from '@material-ui/core/styles';
import { ButtonContainer } from './styled';

import { Button } from '@material-ui/core';

export default function ClassroomNavBar(props) {

    const [buttonColor, setButtonColor] = React.useState("green");

    function toggleHover(e) {
        e.preventDefault();
        if (buttonColor === "red") {
            setButtonColor("green")
        } else {
            setButtonColor("red");
        }
    }

    function displayLogoutButton() {
        if (props.loggedIn) {
            return  (
                <ButtonContainer>
                    <Button
                        style={{
                            "border-width": "2px",
                            "background-color": `${buttonColor}`,
                            color: "white",
                            "font-weight": "800",
                            "border-style": "solid",
                            margin: "16px",
                        }}
                        variant="contained"
                        onClick={props.logout}
                        onMouseEnter={toggleHover}
                        onMouseLeave={toggleHover}
                    >
                        Logout
                    </Button>
                </ButtonContainer>
            )
        }
    }

    return(
        <StylesProvider injectFirst>
        <NavBarWrapper>
            <div style={{padding: '16px', width: "100%"}}>
                <h1 style={{color: 'white'}}>
                    Mr. Tandy's ESL Class
                </h1>
            </div>
            {displayLogoutButton()}
        </NavBarWrapper>
        </StylesProvider>
    )

}