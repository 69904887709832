import React, { PureComponent } from 'react';

import axios from 'axios';

import { StyledRemove } from '../SmallComponents';

export default class DeleteWorksheet extends PureComponent {

    handleDeleteWorksheet = (event) => {
        event.preventDefault();
        const { worksheet, getWorksheets } = this.props;

        axios({
            method: 'delete',
            url: '/api/worksheets',
            data: {
                file_name: worksheet.file_name,
                token: localStorage.easyWeb
            }
        })
        .then(() => getWorksheets())
        .catch(err => {
            console.log(err);
            return null;
        });
    }

    render() {
        return (
            <StyledRemove className="fas fa-trash" add={false} onClick={this.handleDeleteWorksheet}></StyledRemove>
        );
    }
}