import React, { PureComponent } from 'react';

import PurchaseButton from '../PurchaseButton';

import { LevelTitle, LevelWrapper, Price, Title, WorkbookItem, Wrapper } from './styled';

export default class Workbooks extends PureComponent {

    formattingWorkbookObj = (workbooks) => {
        const level1 = [];
        const level2 = [];
        const level3 = [];
        let i;
        for (i = 0; i < workbooks.length; i++) {
            if (workbooks[i].level === 'level-1') {
                level1.push(workbooks[i]);
            }
            if (workbooks[i].level === 'level-2') {
                level2.push(workbooks[i]);
            }
            if (workbooks[i].level === 'level-3') {
                level3.push(workbooks[i]);
            }
        }
        return ([{title: "EASY Part 1 for Beginners", workbooks: level1}, {title: "EASY Part 2 High Beginners / Intermediate", workbooks: level2}
        ///, {title: "EASY at Work", workbooks: level3, }
    ]);
    }

    render() {
        const { addToCart, itemsInCart, workbooks } = this.props;

        const _workbooks = this.formattingWorkbookObj(workbooks);

        return(
            <Wrapper>
                {_workbooks && _workbooks.map((x, index)  =>
                    <div>
                        <LevelTitle key={index}>
                            {x.title}
                        </LevelTitle>
                        <LevelWrapper>
                            {x.workbooks.map(workbook =>
                                <WorkbookItem>
                                    <Title>{workbook.name}</Title>
                                    <Price>{`$${workbook.price}`}</Price>
                                    <PurchaseButton
                                        addToCart={addToCart}
                                        itemsInCart={itemsInCart}
                                        workbook={workbook}
                                    />
                                </WorkbookItem>
                            )}
                        </LevelWrapper>
                </div>)
            }
            </Wrapper>
        )
    }
}