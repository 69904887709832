import React, { PureComponent } from 'react';

import axios from 'axios';

import LevelSelect from '../LevelSelect';
import NavBar from '../NavBar';
import PurchaseRecordTable from '../PurchaseRecordTable';
import RegisterNewUserModal from '../RegisterNewUserModal';
import SalesLeadTable from '../SalesLeadTable';
import WorkbookTable from '../WorkbookTable';
import WorksheetTable from '../WorksheetTable';
import { Spacer, StyledButton, Title, Wrapper } from '../SmallComponents';

import { SelectItem, TableSelector, ColorBlock, SmallStyledInput, StyledInput, StyledTextArea } from './styled';
import NotLoggedInPage from '../NotLoggedInPage';

export default class AdminPage extends PureComponent {

    state = {
                description: '',
                modalVisible: false,
                purchaseRecords: [],
                salesLeads: [],
                select_value: '',
                showPRTable: true,
                showSLTable: false,
                showWBTable: false,
                showWSTable: false,
                workbook_name: '',
                workbook_price: null,
                workbook_to_remove: '',
                workbooks: [],
                worksheet_name: '',
                worksheet_to_remove: '',
                worksheet_url: '',
                worksheets: [],
            };

    workbookInput = React.createRef();
    worksheetInput = React.createRef();

    componentDidMount() {
        this.getSalesLead();
        this.getWorkbooks();
        this.getWorksheets();
        this.getPurchaseRecords();
    }

    addWorksheet = (data) => {
        axios
            .post("/api/worksheets", data)
            .then(() => this.getWorksheets())
            .catch(err => {
                console.log(err);
                return null;
            });
    };

    addWorkbook = (data) => {
        axios
            .post("/api/workbooks", data)
            .then(() => this.getWorkbooks())
            .catch(err => {
                console.log(err);
                return null;
            });
    };

    getWorksheets = () => {
        axios
            .get("/api/worksheets")
            .then(data => this.setState({ worksheets: data.data.result }))
            .catch(err => {
                console.log(err);
                return null;
            });
    };

    getSalesLead = () => {
        axios
            .get("/api/customers")
            .then(data => this.setState({ salesLeads: data.data.result }))
            .catch(err => {
                console.log(err);
                return null;
            });
    };

    getWorkbooks = () => {
        axios
            .get("/api/workbooks")
            .then(data => {
                this.setState({ workbooks: data.data.result })
            })
            .catch(err => {
                console.log(err);
                return null;
            });
    };

    getPurchaseRecords = () => {
        axios
            .get("/api/purchase_records")
            .then(data => {
                this.setState({ purchaseRecords: data.data })
            })
            .catch(err => {
                console.log(err);
                return null;
            });
    }

    openModal = () => {
        this.setState({ modalVisible: true });
    };

    closeModal = () => {
        this.setState({ modalVisible: false });
    };

    registerNewAdmin = (data) => {
        axios
            .post('/api/register', data)
            .then(res => alert(res.data.message))
            .catch(err => {
                console.log(err);
                return null;
            });
    };

    handleAddWorksheetText = (event) => {
        event.preventDefault();
        this.setState({ worksheet_name: event.target.value });
    };

    handleAddWorkbookText = (event) => {
        event.preventDefault();
        this.setState({ workbook_name: event.target.value })
    };

    handleDescriptionChange = (event) => {
        event.preventDefault();
        this.setState({ description: event.target.value });
    }

    handleSelectChange = (event) => {
        event.preventDefault();
        this.setState({ select_value: event.target.value });
    }

    handleUrlChange = (event) => {
        event.preventDefault();
        this.setState({ worksheet_url: event.target.value });
    }

    handlePriceChange = (event) => {
        event.preventDefault();
        this.setState({ workbook_price: event.target.value });
    }

    showSampleTable = (e) => {
        e.preventDefault();
        this.setState({ showWSTable: true, showWBTable: false, showSLTable: false, showPRTable: false });
    };

    showWorkbookTable = (e) => {
        e.preventDefault();
        this.setState({ showWBTable: true, showWSTable: false, showSLTable: false, showPRTable: false });
    }

    showSalesLeadTable = (e) => {
        e.preventDefault();
        this.setState({ showSLTable: true, showWSTable: false, showWBTable: false, showPRTable: false });
    }

    showPurchaseRecordTable = (e) =>{
        e.preventDefault();
        this.setState({showPRTable: true, showSLTable: false, showWSTable: false, showWBTable: false });
    }

    handleWorkbookSubmit = (event) => {
        event.preventDefault();
        const { select_value, workbook_name, workbook_price } = this.state;
        const file = this.workbookInput.current.files[0];
        if (!workbook_name || !workbook_price || !file ) {
            alert('Please fill out all form boxes.')
            return;
        }
        const data = new FormData();
        data.append('file', file);
        data.append('name', workbook_name);
        data.append('price', workbook_price);
        data.append('level', select_value)
        this.addWorkbook(data);
        document.getElementById('workbook-uploader').reset();
        this.setState({ workbook_name: '', workbook_price: '' });
    };

    handleWorksheetSubmit = (event) => {
        event.preventDefault();
        const { worksheet_name, select_value, worksheet_url, description } = this.state;
        const file = this.worksheetInput.current.files[0];
        if (!worksheet_name || !file || !select_value ) {
            alert('Please fill out all form boxes.')
            return;
        }
        const token = localStorage.easyWeb;
        const data = new FormData();
        data.append('file', file);
        data.append('name', worksheet_name);
        data.append('level', select_value);
        data.append('url', worksheet_url);
        data.append('description', description);
        data.append('filetype', 'sample')
        data.append('token', token);
        this.addWorksheet(data);
        document.getElementById('worksheet-uploader').reset();
        this.setState({select_value: '', worksheet_url: '', worksheet_name: '', description: ''});
    };

    fileUploader = (workbook=false) => {
        return (
            <ColorBlock workbook={workbook}>
                <form
                    id={workbook ? 'workbook-uploader' : 'worksheet-uploader'}
                >
                        {!workbook && `Add Display Name here: `}
                        <StyledInput
                            type="text"
                            name="name"
                            id={
                                workbook
                                ? "workbook-name"
                                : "worksheet-name"
                            }
                            placeholder={
                                workbook
                                ? "Workbook Name"
                                : "Sample Kit Name"
                            }
                            onChange={
                                workbook
                                ? this.handleAddWorkbookText
                                : this.handleAddWorksheetText
                            }
                            required
                        />
                        {/* {!workbook && `Add Download Name here: `}
                        {!workbook &&
                            <StyledInput
                                type="text"
                                name="name"
                                id='download-name'
                                placeholder='Download Name'
                                onChange={this.handleAddWorksheetText}
                                required
                            />
                        } */}
                        <br />
                        {`Upload PDF: `}
                        <StyledInput
                            type="file"
                            size={20}
                            name={workbook ? "workbook-file" : "worksheet-file"}
                            ref={workbook ? this.workbookInput : this.worksheetInput}
                            accept=".pdf"
                            required
                        />
                    <br />
                    {workbook && 'Set workbook price here:'}
                    {workbook && <SmallStyledInput
                                    type="number"
                                    name="workbook-price"
                                    value={this.state.workbook_price}
                                    onChange={this.handlePriceChange}
                                    min="0.00"
                                    max="99.99"
                                    step=".01"
                                />}
                    {`Select content level here: `}
                    <LevelSelect handleSelectChange={this.handleSelectChange} />
                    <br />
                    { !workbook && `Add link for file streaming here: `}
                    { !workbook &&
                        <StyledInput
                            type="url"
                            name="video-link"
                            placeholder="Video link"
                            onChange={this.handleUrlChange}
                        />
                    }
                    <br />
                    {!workbook && `Add description for sample kit here: `}
                    {!workbook &&
                            <StyledTextArea
                                name="sample-description"
                                placeholder="Add a description"
                                onChange={this.handleDescriptionChange}
                                maxlength={2000}
                            />
                    }
                    <br />
                    <StyledButton
                        add={true}
                        onClick={
                            workbook
                            ? this.handleWorkbookSubmit
                            : this.handleWorksheetSubmit
                        }
                    >
                        Add {workbook ? "Workbook" : "Worksheet"}
                    </StyledButton>
                </form>
            </ColorBlock>
        );
    };

    showAdminPage = () => {
        const { modalVisible, purchaseRecords, salesLeads, showPRTable, showSLTable, showWSTable, showWBTable, worksheets, workbooks } = this.state;
        const { logOut, loggedIn } = this.props;

        if (loggedIn) {
            return (
            <div>
                <RegisterNewUserModal
                    closeModal={this.closeModal}
                    registerNewAdmin={this.registerNewAdmin}
                    visible={modalVisible}
                />
                <Title>Admin Page</Title>
                <TableSelector>
                    <SelectItem
                        highlighted={showPRTable}
                        onClick={this.showPurchaseRecordTable}
                    >
                        Purchase Record Table
                    </SelectItem>
                    <SelectItem
                        highlighted={showSLTable}
                        onClick={this.showSalesLeadTable}
                    >
                        Sales Lead Table
                    </SelectItem>
                    <SelectItem
                        highlighted={showWBTable}
                        onClick={this.showWorkbookTable}
                    >
                        Workbook Table
                    </SelectItem>
                    <SelectItem
                        highlighted={showWSTable}
                        onClick={this.showSampleTable}
                    >
                        Samples
                    </SelectItem>
                </TableSelector>
                {showWSTable && <WorksheetTable
                    fileUploader={this.fileUploader}
                    getWorksheets={this.getWorksheets}
                    worksheets={worksheets}
                />}

                {showWBTable && <WorkbookTable
                    fileUploader={this.fileUploader}
                    getWorkbooks={this.getWorkbooks}
                    workbooks={workbooks}
                />}

                {showSLTable && <SalesLeadTable
                    getSalesLead={this.getSalesLead}
                    salesLeads={salesLeads}
                />}

                {showPRTable && <PurchaseRecordTable
                    purchaseRecords={purchaseRecords}
                />}
                <Spacer />
                <button onClick={this.openModal}>Register New Admin</button>
            </div>
            )
        }
        else {
            return <NotLoggedInPage />
        }
    }

    render() {
        const { loggedIn, logOut } = this.props;
        return (
            <Wrapper>
            <NavBar
                admin={loggedIn}
                logOut={logOut}
            />
            {this.showAdminPage()}
            </Wrapper>
        )
    }

}