import styled from 'styled-components'

import { RoundedBox } from '../SmallComponents';

export const StyledButton = styled.button`
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
    background: #3264a8;
    border-radius: 10px;
    padding: 8px 16px;
    margin: 8px;
    color: white;
    font-weight: 700;
    cursor: pointer;
    border-width: 0px;
`;

export const StyledForm = styled.form`
    width: 50%;
    margin: auto;
    @media screen and (max-width: 800px) {
        width: 100%;
    }
`;

export const StyledLabel = styled.label`
    display: block;
    /* line-height: 3; */
    font-weight: 500;
    width: 100%;
    margin: auto;
`;

export const SmallText = styled.div`
    font-size: 12px;
    font-style: italic;
`;

export const StyledRoundedBox = styled(RoundedBox)`
    text-align: center;
`;