import React from 'react';

import VideoPlayerContainer from '../VideoPlayerContainer';
import DownloadButton from '../DownloadButton';
import { StyledIcon } from '../SmallComponents';

import {
    BoxWrapper,
    LeftWrapper,
    LevelWrapper,
    RightWrapper,
    SampleDescription,
    SeriesDescription,
    StyledLink,
    SubtitleLeft,
    SubtitleWrapper,
    WorksheetTitle,
    WorksheetWrapper,
    Wrapper,
} from './styled';

export default function WorksheetSamples(props) {

    const worksheets = props.worksheets;

        return(
            <Wrapper>
                {worksheets && worksheets.length > 0 && worksheets.map( (x, index) =>
                    <>
                    <LevelWrapper key={index} className="level-wrapper">
                        <SubtitleWrapper>
                            <LeftWrapper>
                                <SubtitleLeft key={`${index}-${x}`}>
                                    {x.title}
                                </SubtitleLeft>
                                <SeriesDescription>
                                    {x.description}
                                </SeriesDescription>
                            </LeftWrapper>
                            <RightWrapper>
                                <StyledLink href='/shop'>
                                    <StyledIcon className="fas fa-shopping-cart">
                                    </StyledIcon>
                                    Purchase Workbooks
                                </StyledLink>
                            </RightWrapper>
                        </SubtitleWrapper>
                        <BoxWrapper className="box-wrapper">
                        {x.worksheets.map((x, index) =>
                        <WorksheetWrapper className="worksheet-wrapper">
                            <WorksheetTitle key={`${index}-${x.title}`} className="worksheet-title">
                                {x.title}
                            </WorksheetTitle>
                            <SampleDescription
                                key={`${index}-${x.description}`}
                            >
                                {x.description}
                            </SampleDescription>
                            <VideoPlayerContainer search_term={x.search_term} />
                            <DownloadButton
                                downloadSample={props.downloadSample}
                                worksheet={x}
                            />
                        </WorksheetWrapper>
                        )}
                        </BoxWrapper>
                    </LevelWrapper>
                    </>
                )}
            </Wrapper>
        )
        }