import React, { PureComponent } from 'react';

import ShoppingCartItem from '../ShoppingCartItem';

import { Title } from '../SmallComponents';
import { CartObj, CartTotal, CartWrapper, CloseIcon, ModalContent, NoItems, StyledButton, Wrapper } from './styled';

export default class ShoppingCartModal extends PureComponent {

    handleClick = () => {
        const { redirectToCheckout } = this.props;
        redirectToCheckout();
    }

    render() {
        const {
            cartTotal,
            cartItems,
            closeModal,
            removeAll,
            removeOne,
            visible
        } = this.props;
        return(
            <Wrapper visible={visible}>
                <ModalContent>
                    <CloseIcon
                        onClick={closeModal}
                        className="fas fa-times"
                    >
                    </CloseIcon>
                    <Title>Shopping Cart</Title>
                        {(cartItems && cartItems.length > 0)
                            ?
                            <CartWrapper>
                                <CartObj>
                                    <tr>
                                        <th>Item</th>
                                        <th>Price</th>
                                        <th>Quantity</th>
                                        <th>Remove</th>
                                    </tr>
                                    {cartItems.map(item => (
                                        <ShoppingCartItem
                                            removeOne={removeOne}
                                            item={item}
                                            updateCartItems={this.updateCartItems}
                                        />
                                    ))}
                                </CartObj>
                            </CartWrapper>
                            : <NoItems>No items in cart.</NoItems>
                        }
                        <CartTotal>
                            Total: {cartItems ? `$${cartTotal}` : `$0.00` }
                        </CartTotal>
                    <StyledButton disabled={!cartItems.length > 0} onClick={this.handleClick}>Proceed to Checkout</StyledButton>
                    <StyledButton disabled={!cartItems.length > 0} onClick={removeAll}>Remove All Items</StyledButton>
                </ModalContent>
            </Wrapper>
        );
    }
}