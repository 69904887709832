import React, { PureComponent } from 'react';

import { StyledSelect } from './styled';

export default class LevelSelect extends PureComponent {
    state = {
        options: [
            {
                name: 'Select level...',
                value: null,
            },
            {
                name: 'EASY Part 1 for Beginners',
                value: 'level-1'
            },
            {
                name: 'EASY Part 2 for High Beginners / Intermediate',
                value: 'level-2'
            },
            {
                name: 'EASY at Work',
                value: 'level-3'
            },
            {
                name: 'Scope & Sequence',
                value: 's-n-s'
            },
        ],
    };

    render() {
        const { options } = this.state;
        const { handleSelectChange } = this.props;
        return (
            <span >
                <StyledSelect id="level-select" onChange={handleSelectChange}>
                    {options.map(level => (
                        <option key={level.value} value={level.value}>
                            {level.name}
                        </option>
                    ))}
                </StyledSelect>
            </span>
        )
    }
}