import styled from 'styled-components';

export const PhotoWrapper = styled.div`
    width: 50%;
    padding: 24px 0;
    @media screen and (max-width: 800px)  {
        text-align: center;
        margin: 0 0 32px 0;
        width: 100%;
    }
`;

export const DogPhoto = styled.img`
    margin: 0 auto 16px;
    width: 90%;
    margin-bottom: 16px;
`;

export const StarWrapper = styled.div`
    text-align: center;
    display: flex;
`;