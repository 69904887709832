import React, { PureComponent } from 'react';

import DeleteWorkbook from '../DeleteWorkbook';

import { Spacer, TableTitle, StyledButton, StyledTable, StyledRow } from '../SmallComponents';

export default class WorkbookTable extends PureComponent {

    state = {
        showWBUploader: false,
    };

    workbookInput = React.createRef();

    showWBUploader = () => {
        const { showWBUploader } = this.state;
        this.setState({ showWBUploader: !showWBUploader });
    };

    fileListParser = (workbooks=[]) => {
        const { getWorkbooks } = this.props;
        const tableContents = workbooks.map((workbook) =>
            <StyledRow>
                <td>{workbook.name}</td>
                <td>{workbook.file_name}</td>
                <td>{workbook.date_added}</td>
                {workbook.price ? <td>{workbook.price}</td> : null}
                <td><DeleteWorkbook getWorkbooks={getWorkbooks} workbook={workbook} /></td>
            </StyledRow>
        )
        return tableContents;
    };

    render() {
        const { workbooks, fileUploader } = this.props;
        const { showWBUploader } = this.state;
        return(
           <div>
                <TableTitle>Workbooks for Purchase</TableTitle>
                <Spacer height={4}/>
                <StyledTable>
                    <tr>
                        <th>Workbook Name</th>
                        <th>Workbook File Name</th>
                        <th>Date Uploaded</th>
                        <th>Pricing</th>
                        <th>Remove?</th>
                    </tr>
                    {this.fileListParser(workbooks)}
                </StyledTable>
                <Spacer height={10}/>
                <StyledButton
                    add
                    onClick={this.showWBUploader}>
                    {showWBUploader ? "Hide" : "Show"} Workbook Uploader</StyledButton>
                {showWBUploader && fileUploader(true)}
                {/* true used for workbooks */}
                <Spacer />
            </div>
        )
    };
}