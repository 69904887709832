import styled from 'styled-components';

import { StyledIcon } from '../SmallComponents';

export const Wrapper = styled.div`
    /* border-style: dotted;
    border-color: black;
    border-radius: 8px;
    padding: 8px;
    width: 25%;
    margin: 16px auto; */
    display: ${props => props.visible ? "block;" : "none;" };
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
`;

export const ModalContent = styled.div`
    padding: 50px 0;
    background-color: #fefefe;
    margin: 100px auto; /* 15% from the top and centered */
    padding: 36px;
    border: 1px solid #888;
    border-radius: 20px;
    width: 60%; /* Could be more or less, depending on screen size */
    /* display: flex; */
`;

export const CartTotal = styled.div`
    padding: 16px;
`;

export const NoItems = styled.div`
    padding: 16px;
    margin: 16px;
    background-color: #FFFFE0;
    width: 50%;
    margin: auto;
    border-radius: 16px;
`;

export const ItemWrapper = styled.div`
    min-height: 320px;
`;

export const CloseIcon = styled(StyledIcon)`
    float: right;
`;

export const CartHeader = styled.div`
    margin: auto;
    width: 100%;
`

export const HeaderItem = styled.th`
    font-weight: 600;
    width: 25%;
`;

export const CartObj = styled.table`
    margin: auto;
    width: 90%;
    padding: 16px;
    overflow: scroll;
`;


export const DigitalRights = styled.input`
    margin: 8px auto;
    width: 50%;
    display: block;
`;

export const InstitutionName = styled.input`
    margin: 8px auto;
    width: 50%;
    display: block;
`;

export const StyledButton = styled.button`
    box-shadow: ${ props => props.disabled ? 'none' : '0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)' };
    background: ${ props => props.disabled ? "#EEEEEE" : "#3264a8" };
    border-radius: 10px;
    padding: 8px 16px;
    margin: 8px;
    color: ${ props => props.disabled ? "#ABABAB" : "white" };
    font-weight: 700;
    cursor: ${ props => props.disabled ? "default" : "pointer" };
    border-width: 0px;

`
export const CartWrapper = styled.div`
    overflow: scroll;
    width: 95%;
    margin: auto;
`;