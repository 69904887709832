import styled, { css } from 'styled-components';
import {
    Link,
} from "react-router-dom";


export const StyledContainer = styled.div`
    text-align: center;
    padding-right: 16px;
    padding-left: 16px;
    margin: auto;
    width: 95%;
`;

export const StyledInlineWrapper = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    margin: auto;
    text-align: center;
    @media screen and (max-width: 480px) {
        width: 95%;
    }
`;

export const StyledSubtitle = styled.div`
    font-size: 1.5em;
    font-weight: bold;
    width: 70%;
    margin: auto;
    padding: 8px;
    @media screen and (max-width: 480px) {
        width: 95%;
    }
`;

export const VideoContainer = styled.div`
    margin-left: auto;
    margin-right: auto;
    width: 60%;
    padding: 16px;
    margin-top: 16px;
    @media screen and (max-width: 800px) {
        width: 95%;
    }
`;

export const Wrapper = styled.div`
    text-align: center;
    background: #F5F5F5;
    width: 100%;
    height: 100vh;
`;

export const StyledText = styled.div`
    max-width: 50%;
    margin: auto;
    text-align: center;
`;

export const StyledHeading = styled.h1`
    margin: 0;
`;

export const StyledLink = styled(Link)`
    text-decoration: none;
    display: flex;
    flex-direction: column;
    width: 50%;
    ${props => props.disabled && css`
        color: #E3E6E8
    `}
    ${props => props.hide && css`
        visibility: hidden;
    `}
`;

export const SubContainer = styled.div`
    display: flex;
    @media only screen and (max-width: 800px) {
        flex-wrap: wrap;
    }
`;