import React, { PureComponent } from 'react';

import axios from 'axios';
import Cookies from 'universal-cookie';
import { Redirect } from 'react-router-dom'

import CallToAction from '../CallToAction';
import NavBar from '../NavBar';
import PurchaseStar from '../PurchaseStar';
import SnSStar from '../SnSStar';
import WorksheetSamples from '../WorksheetSamples';
import { downloadFile } from '../helperfunctions.js'
import { Spacer, Title, Wrapper } from '../SmallComponents';

import { StarWrapper, StyledText, TextWrapper } from './styled';

export default class DownloadPage extends PureComponent {
    state = {
        modalVisibility: false,
        redirect: false,
        worksheets: [],
    };

    componentDidMount() {
        this.getSamples();
    }

    renderRedirect = () => {
        if (this.state.redirect) {
            return <Redirect to='/shop' />
        }
    }

    addSalesLead = (data) => {
        axios({
            url: '/api/customers',
            method: 'POST',
            data
        })
    };

    getSamples = () => {
        axios
            .get("/api/samples")
            .then(data => this.setState({ worksheets: data.data }))
            .catch(err => {
                return null;
            });
     };

    modalButtonClick = (data) => {
        this.addSalesLead(data);
        const cookies = new Cookies();
        cookies.remove("three-downloads", { path: '/' });
        cookies.set("download-all", true, { path: '/'});
        this.closeModal();
    };

    closeModal = () => {
        this.setState({ modalVisibility: false });
    };

    setRedirect = () => {
        this.setState({ redirect: true });
    }

    downloadSample = (worksheet) => {
        const cookies = new Cookies();
        if (cookies.get("three-downloads") && !cookies.get("download-all")) {
            this.setState({ modalVisibility: true });
            return
        } else if (cookies.get("download-all")) {
            downloadFile(worksheet.download_link);
            return
        } else if (cookies.get("one-download")) {
            downloadFile(worksheet.download_link);
            cookies.remove("one-download");
            cookies.set("two-downloads", true, { path: '/' });
            return
        } else if (cookies.get("two-downloads")) {
            downloadFile(worksheet.download_link);
            cookies.remove("two-downloads");
            cookies.set("three-downloads", true, { path: '/' });
            return
        } else {
            downloadFile(worksheet.download_link);
            cookies.set("one-download", true, { path: '/' });
            return
        }
    };

    render() {
        const { modalVisibility, worksheets } = this.state;
        return (
            <Wrapper>
                {this.renderRedirect()}
                <NavBar />
                <Title>
                    EASY, the ESL Series Samples & Videos
                </Title>
                <StarWrapper>
                    <SnSStar />
                    <PurchaseStar />
                 </StarWrapper>
                {/* <TextWrapper>
                    <StyledText>
                        Download free samples of Mr. Tandy's ESL Classroom worksheets and watch the videos.
                    </StyledText>
                </TextWrapper> */}
                <Spacer />
                <CallToAction
                    buttonClick={this.modalButtonClick}
                    closeModal={this.closeModal}
                    setRedirect={this.setRedirect}
                    visible={modalVisibility}
                />
                <WorksheetSamples
                    downloadSample={this.downloadSample}
                    worksheets={worksheets}
                />
            </Wrapper>
        );
    }
}