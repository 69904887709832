import React, { PureComponent } from 'react';

import { OverlayText, StyledStar } from '../SmallComponents';

import { Wrapper } from './styled';

export default class SnSStar extends PureComponent {

    render() {
        return(
            <Wrapper>
                <a href="/samples">
                    <OverlayText>
                        Download <br />Free Sample <br />Worksheets<br />
                    </OverlayText>
                    <StyledStar red className="fas fa-star"></StyledStar>
                </a>
            </Wrapper>
        );
    }
};