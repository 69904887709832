import React, { useEffect, useState } from 'react';
import axios from 'axios';

import VideoPlayerComponent from '../VideoPlayerComponent';

export default function VideoPlayerContainer(props) {

    const [loading, setLoading] = useState(true);
    const [url, setUrl] = useState('')

    useEffect(() => {
        axios.get(`/api/get_video?search_term=${props.search_term}`)
        .then(function (response) {
            setUrl(response.data);
            setLoading(false);
        }, [props.search_term])
        .catch(function (error) {
            console.log(error)
            return "Error."
        })
    }, [props.search_term])

    if (loading) {
        return <div>Loading...</div>
    } else { return <VideoPlayerComponent url={url} /> }
}