import axios from 'axios';
import FileSaver from 'file-saver';

export const downloadFile = (file_name) => {
    axios({
        url: `/api/download_worksheet/?file_name=${file_name}`,
        responseType: 'blob',
        method: 'GET'
    })
    .then((res) => {
        const blob = new Blob([res.data], { type: 'application/pdf' });
        blob.name = file_name;
        const PDFurl = window.URL.createObjectURL(blob);
        window.open(PDFurl);
        let filename = res.headers["x-suggested-filename"];
        FileSaver.saveAs(res.data, filename);
        })
    .catch(err => {
        console.log(err);
        return err.message;
    });
};