import React, { useState, useEffect } from 'react';

import axios from 'axios';

import VideoPlaylistComponent from '../VideoPlaylistComponent';

export default function VideoPlayerContainer(props) {

    const [videoList, setVideoList] = useState([])
    const { loggedIn } = props;

    // API call to get list of videos
        useEffect(() => {
            if (loggedIn) {
                axios.get(`/api/get_video_list`)
                .then(function (response) {
                    setVideoList(response.data)
                })
                .catch(function (error) {
                    console.log(error)
                    return "Could not load video list."
                })
            }
        }, [])

    return (
        <VideoPlaylistComponent videoList={videoList} {...props} />
    )
}