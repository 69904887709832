import React from "react";

import useContextMenu from "../UseContextMenu";

const VideoContextMenu = ({ outerRef }) => {
  const { xPos, yPos, menu } = useContextMenu(outerRef);

  if (menu) {
    return (
      <ul className="menu" style={{ top: yPos, left: xPos }}>
        <li>Buy workbooks</li>
        <li>Learn more</li>
      </ul>
    );
  }
  return <></>;
};

export default VideoContextMenu;