import React, { PureComponent } from 'react';
import moment from 'moment';

import { Spacer, StyledRow } from '../SmallComponents';
import { TableHead, TableWrapper, StyledButton, StyledTable, StyledTd, Wrapper } from './styled';

export default function PurchaseRecordTable(props) {

    function workbookParser(workbooks) {
        const wbooks = workbooks.map((workbook, index) => (
            <tr><td>{`(${index+1}) ${workbook} `}</td></tr>
        ))
        return wbooks
    }

    function purchaseRecordParser() {
        const { purchaseRecords } = props;
        const tableContents = purchaseRecords.map((record, index) =>
        <StyledRow even={index % 2 === 0 ? true : false}>
            <StyledTd>{record.purchase_id}</StyledTd>
            <StyledTd>{moment(record.sales_date).format("MM/D/YYYY")}</StyledTd>
            <StyledTd>{record.email}</StyledTd>
            <StyledTd><table>{workbookParser(record.workbooks)}</table></StyledTd>
            <StyledTd>${record.total}</StyledTd>
            <StyledTd>{record.teacher_name}</StyledTd>
            <StyledTd>{record.organization}</StyledTd>
            <StyledTd>{record.country}</StyledTd>
            <StyledTd>{record.postal_code}</StyledTd>
        </StyledRow>
    )
    return tableContents
    }

        return (
            <div>
            <Wrapper>
                <Spacer height={4} />
                <TableWrapper>
                <StyledTable>
                    <TableHead>
                        <tr>
                            <th>Sales Order #</th>
                            <th>Sales Date</th>
                            <th>Email</th>
                            <th>Workbooks</th>
                            <th>Price</th>
                            <th>Teacher Name</th>
                            <th>Organization</th>
                            <th>Country</th>
                            <th>Postal Code</th>
                        </tr>
                    </TableHead>
                    {purchaseRecordParser()}
                </StyledTable>
                </TableWrapper>
                <Spacer />
                <a href='/api/purchase_table'><StyledButton>
                    Download Purchase Records
                </StyledButton></a>
            </Wrapper>
            </div>
        )
    }
