import styled from 'styled-components';
import { StyledActionButton, Subtitle } from '../SmallComponents';

export const BoxWrapper = styled.div`
    text-align: center;
    display: flex;
    @media screen and (max-width: 800px) {
        flex-direction: column;
    }
`;

export const WorksheetBox = styled.div`
    border: solid;
    border-color: grey;
    border-width: 1px;
    border-radius: 5px;
    width: 80%;
    display: inline-block;
`;

export const SampleDescription = styled.div`
    font-style: italic;
    padding: 8px 24px;
    height: 40px;

    @media screen and (max-width: 800px) {
        margin-bottom: 16px;
    }
`;

export const WorksheetTitle = styled.div`
    margin: 10px;

    @media screen and (max-width: 800px) {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 32px;
    }
`;

export const WorksheetWrapper = styled.div`
    width: 33%;
    @media screen and (max-width: 800px) {
        width: 90%;
        margin: auto;
    }
`;

export const Wrapper = styled.div`
    text-align: center;
`;

export const LevelWrapper = styled.div`
    background-color: #ECECEC;
    width: 90%;
    margin: auto;
    border-radius: 16px;
    margin-bottom: 24px;
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
    /* Mobile Settings */
    @media screen and (max-width: 800px) {
        display: flex;
        flex-direction: column;
    }
`;

export const StyledI = styled.i`
    transform: ${props => props.visibility ? 'rotate(180deg)' : 'none'};
`;

export const VideoButton = styled(StyledActionButton)`
    margin-bottom: 16px;
    /* @media screen and (max-width: 800px) {
        display: none;
    } */
`;

export const ButtonWrapper = styled.div`
    text-align: center;
`;

export const SubtitleWrapper = styled.div`
    text-align: left;
    padding-left: 16px;
    background:#D3d3d3;
    border-radius: 16px 16px 0 0;
    padding: 16px 0;
    @media screen and (max-width: 800px) {
        display: flex;
        flex-direction: column;
        text-align: center;
    }
`;

export const SubtitleLeft = styled(Subtitle)`
    padding-left: 16px;
    display: block;

    @media screen and (max-width: 800px) {
        padding: 0;
    }
}
`;

export const SeriesDescription = styled.div`
    padding-left: 16px;
    display: block;
    @media screen and (max-width: 800px) {
        padding: 0;
    }
`;

export const RightWrapper = styled.div`
    padding-right: 16px;
    display: inline-block;
    text-align: right;
    width: 15%;
    line-height: 32px;
    @media screen and (max-width: 800px) {
        width: 100%;
        text-align: center;
        margin: 16px auto 0 auto;
    }
`;

export const LeftWrapper = styled.div`
    padding-left: 16px;
    display: inline-block;
    text-align: left;
    width: 80%;
    @media screen and (max-width: 800px) {
        margin: auto;
        text-align: center;
        padding: 0;
    }
`;

export const StyledLink = styled.a`
    color: black;
    text-decoration: none;
    &:hover {
        text-decoration: underline;
        cursor: pointer;
    }
`;

export const IframeWrapper = styled.div`
    overflow: scroll;
`;