import React, { PureComponent } from 'react';
import axios from 'axios';
import { Redirect } from 'react-router-dom'

import NavBar from '../NavBar';
import ShoppingCartModal from '../ShoppingCartModal';
import Workbooks from '../Workbooks';

import { CartIcon, CheckoutText, IconWrapper, StyledText, StyledTitle, WorkbooksWrapper, Wrapper } from './styled';

export default class ShopPage extends PureComponent {

    state = {
        cartCount: 0, // How many workbooks are in cart
        cartItems: [], // An array of workbooks in cart, NEW!!!
        cartTotal: 0.00,
        itemsInCart: [], // An array of workbook ids, used with <Workbooks />
        modalVisibility: false,
        redirect: false,
        workbooks: [], // Array of workbook objects available, <Workbooks />
    }

    componentDidMount() {
        this.setCartState();
        axios
            .get('/api/workbooks')
            .then(res => {
                this.setState( { workbooks: res.data.result })
            })
            .catch(err => {
                console.log(err);
                return null;
            });
    }

    setCartState = () => {
        // set cart items  in state
        const cartItems = this.getCartItems();
        // set cart count in state
        const cartCount = this.countCartItems(cartItems);
        // set cart total in state
        const cartTotal = this.getCartTotal(cartItems);
        // set id array
        const itemsInCart = cartItems.map(item => item.id);
        this.setState({ cartCount, cartItems, cartTotal, itemsInCart })
    }

    countCartItems = (cart) => {
        let cartCount = 0;
        if (cart) {
            let i;
            for (i = 0; i < cart.length; i++ ) {
                cartCount = cartCount + cart[i].qty;
            }
        }
        return cartCount;
    }

    getCartItems = () => {
        const _cartItems = localStorage.EasyCart;
        if (_cartItems) {
            const cartItems = JSON.parse(_cartItems);
            return cartItems;
        }
        return [];
    }

    getCartTotal = (cartItems) => {
        if (cartItems) {
            const _array = cartItems.map(item =>  item.price * item.qty);
            const sum = _array.reduce(function(a, b){
                return parseFloat(a) + parseFloat(b);
            }, 0);
            return sum.toFixed(2);;
        }
        return 0.00;
    }

    itemCheck = (cart, workbook) => {
        if (cart) {
            const _cart = JSON.parse(cart);
            const matchingItem = _cart.filter(item => item.id === workbook.id);
            if (matchingItem.length > 0) {
                return matchingItem[0]
            }
            return false
        }
        return false
    }

    addToCart = (workbook) => {
        const cart = localStorage.EasyCart;
        if (!cart) {
            let _array = [];
            workbook.qty = 1;
            _array.push(workbook);
            localStorage.setItem("EasyCart", JSON.stringify(_array));
            this.setCartState()
        }
        if (cart) {
            const _cart = JSON.parse(cart);
            const check = this.itemCheck(cart, workbook);
            if (check) {
                let i;
                for (i = 0; i < _cart.length; i++) {
                    if (_cart[i].id === workbook.id) {
                        _cart[i].qty = _cart[i].qty + 1;
                    }
                }
            } else {
                workbook.qty = 1;
                _cart.push(workbook);
            }
            localStorage.setItem("EasyCart", JSON.stringify(_cart));
            this.setCartState();
        }
    }

    removeOneFromCart = (workbook) => {
        const { cartItems } = this.state;
        if (cartItems) {
            const remainingItems = cartItems.filter(item => item.id !== workbook.id)
            localStorage.setItem("EasyCart", JSON.stringify(remainingItems));
            this.setCartState();
        }
    }

    removeAllFromCart = () => {
        localStorage.removeItem("EasyCart");
        this.setCartState();
    }

    renderRedirect = () => {
        const { cartItems, cartTotal } = this.state;
        if (this.state.redirect === true) {
            return <Redirect to={{
                pathname: '/checkout',
                state: { cartItems, cartTotal}
            }}/>
        }
    }

    openModal = () => {
        this.setState({ modalVisibility: true });
    }

    closeModal = () => {
        this.setState({ modalVisibility: false });
        this.setCartState();
    }

    redirectToCheckout = () => {
        this.setState({ redirect: true })
    }

    render() {
        const { cartCount, cartItems, cartTotal, itemsInCart, modalVisibility, redirect, workbooks } = this.state;
        if (redirect) {
            return <Redirect to={{
                pathname: '/checkout',
                state: { cartItems, cartTotal }
            }}/>
        }
        return(
            <Wrapper>
                <NavBar />
                <IconWrapper onClick={this.openModal}>
                    {cartCount}
                    <CartIcon
                        className="fas fa-shopping-cart"
                    >
                    </CartIcon><br/>
                    <CheckoutText>Checkout</CheckoutText>
                </IconWrapper>
                <StyledTitle>EASY, the ESL Series</StyledTitle>
                <StyledText>The Teacher Guides and Worksheets are provided as downloadable files (PDFs). You can copy them for use by your students. You are not allowed to send these files to anyone not working at your site.</StyledText>
                <WorkbooksWrapper>
                    <Workbooks
                        addToCart={this.addToCart}
                        itemsInCart={itemsInCart}
                        workbooks={workbooks}
                    />
                </WorkbooksWrapper>
                <ShoppingCartModal
                    cartTotal={cartTotal}
                    cartItems={cartItems}
                    closeModal={this.closeModal}
                    removeAll={this.removeAllFromCart}
                    removeOne={this.removeOneFromCart}
                    redirectToCheckout={this.redirectToCheckout}
                    renderRedirect={this.renderRedirect}
                    visible={modalVisibility}
                />
            </Wrapper>
        );
    }

}