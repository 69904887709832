import React, { PureComponent } from 'react';

import { RemoveIcon, StyledCell } from './styled';

export default class ShoppingCartItem extends PureComponent {

    handleClick = () => {
        const { item, removeOne, updateCartItems } = this.props;
        removeOne(item);
        updateCartItems();
    }

    render() {
        const { item } = this.props;
        return(
            <tr>
                <StyledCell>{item.name}</StyledCell>
                <StyledCell>{`$${item.price}`}</StyledCell>
                <StyledCell>{item.qty}</StyledCell>
                <StyledCell>
                    <RemoveIcon onClick={this.handleClick} className="fas fa-times"></RemoveIcon>
                </StyledCell>
            </tr>
        );
    }
}