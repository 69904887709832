import styled from 'styled-components';

import { StyledTable } from '../SmallComponents';

export const Wrapper = styled.div`
    text-align: center;
    margin: auto;
    min-height: 760px;
`;

export const PurchaseTable = styled(StyledTable)`
    width: 40%;
    border-spacing: 0;
`;

export const BoldText = styled.span`
    font-weight: 600;
`;

export const StyledTd = styled.td`
    border-color: black;
    border-width: .5px;
    border-style: solid;
`;

export const StyledButton = styled.button`
    padding: 16px;
    border-radius: 16px;
    background-color: #357EC0;
    color: white;
    font-size: 16px;
    font-weight: 600;
`;