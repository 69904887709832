import styled, { css } from 'styled-components';

import { StyledInput } from '../SmallComponents';


export const Modal = styled.div`
    display: ${props => props.visible ? 'block' : 'none'};
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
`;

export const ModalContent = styled.div`
    padding: 50px 0;
    background-color: #fefefe;
    margin: 100px auto; /* 15% from the top and centered */
    padding: 36px;
    border: 1px solid #888;
    border-radius: 20px;
    width: 80%; /* Could be more or less, depending on screen size */
    text-align: center;
`;

export const ButtonWrapper = styled.div`
    width: 90%;
    text-align: center;
    margin: auto;
`

export const SubmitButton = styled.input`
    background: #3264a8;
    color: white;
    border-radius: 5px;
    font-size: 16px;
    padding: 8px 16px;
    width: 25%;
    cursor: pointer;
    margin: 8px;
`;

export const NoThanks = styled.button`
    background: #3264a8;
    color: white;
    border-radius: 5px;
    font-size: 16px;
    padding: 8px 16px;
    width: 25%;
    cursor: pointer;
    margin: 8px;
`

export const StyledSelect = styled.select`
`;

export const StyledOption = styled.option`
`;

export const StyledForm = styled.form`
`;

export const FormattingDiv = styled.div`
    display: flex;
    flex-direction: ${props => props.direction};
    justify-content: center;
`;

export const AddressInput = styled(StyledInput)`
    text-align: center;
    ${props => props.right && css`
        margin-right: 4px;
    `}
    ${props => props.left && css`
        margin-left: 4px;
    `};
    ::placeholder,
    ::-webkit-input-placeholder {
        text-align: center;
    }
    :-ms-input-placeholder {
        text-align: center;
    }
`;

export const ModalHeader = styled.div`
    width: 50%;
    display: block;
    margin: auto;
    font-size: 32px;
`;

export const CenteringWrapper = styled.div`
    text-align: center;
`;