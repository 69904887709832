import styled from 'styled-components';

import { StyledIcon } from '../SmallComponents';

export const WorkbookPurchaseButton = styled.button`
    background: #357EC0;
    padding: 8px;
    border-radius: 8px;
    color: white;
    font-weight: 500;
    font-size: 16px;
    cursor: pointer;
    border: none;
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
`;

export const GreenCheckMark = styled(StyledIcon)`
    color: green;
`;