import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
    overflow: scroll;
    ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
    }
    ::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background-color: rgba(0, 0, 0, .5);
        -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
    }
    height: ${props => props.height}px;
    @media screen and (max-width: 800px) {
        width: 100%;
        margin: auto;
        height: 750px;
        overflow: scroll;
    }
    @media screen and (max-width: 480px) {
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        overflow: scroll;
        height: 500px;
        margin-bottom: 24px;
        padding-bottom: 8px;
   }
`;

export const StyledContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 30%;
    margin-right: 36px;
    @media only screen and (max-width: 800px) {
        width: 100%;
    }
    @media only screen and (max-width: 480px) {
        width: 100%;
    }
`;