import styled, { css, keyframes } from 'styled-components';

export const Wrapper = styled.div`
    text-align: center;
    min-height: 760px;
    padding-bottom: 24px;
`;

export const Text = styled.div`
    font-size: 18px;
    display: inline-block;
    @media only screen and (max-width: 800px) {
        margin: 0 50px;
        width: 90%;
    }
    @media only screen and (max-width: 600px) {
        margin: 0 20px;
        width: 90%;
    }
    ${props => props.left && css`
        text-align: left;
        padding: 0 24px;
        display: block;
    `}

    ${props => props.second && css`
        padding: 0 48px;
    `}

    ${props => props.third && css`
        padding: 0 72px;
    `}

    ${props => props.smaller && css`
        width: 70%;
    `}
`;


export const Spacer = styled.div`
    height: 32px;
`;

export const Subtitle = styled.div`
    font-weight: 600;
    font-size: 20px;
    margin: 20px;
`;

export const Title = styled.h1`
    padding: 32px 0;
    margin: auto;
    ${props => props.left && css`
        text-align: left;
        margin: 0 0 0 24px;
    `}
    ${props => props.underlined && css`
        text-decoration: underline;
    `}

    @media only screen and (max-width: 800px) {
        width: 100%;
    }
`;

export const StyledLabel = styled.label`
    font-size: 10px;
`;

export const StyledInput = styled.input`
    background: #e1e3e1;
    border-radius: 5px;
    border-width: 0;
    height: 24px;
    width: 50%;
    margin-bottom: 5px;
    ${props => props.split && css`
        width: calc(49%/${props.split});

    `}
    ${props => props.width && css`
        width: ${props.width}%;
    `}
`;

export const TableTitle = styled.text`
    font-weight: 500;
    font-size: 18px;
`;

export const StyledTable = styled.table`
    width: 80%;
    margin: auto;
`;

export const DownloadButton = styled.button`
    background-color: ${props => props.blue ? "rgb(53,126,192)" : "rgb(246,157,65)" };
    color: white;
    font-size: 20px;
    border-radius: 10px;
    height: 40px;
    width: auto;
    padding: 8px;
    font-weight: 500;
    max-height: 300px;
    overflow: scroll;
    cursor: pointer;

`;

export const StyledButton = styled.button`
    background-color: ${props => props.add ? "#00b35c" : "#ff1a1a"};
    font-size: 14px;
    cursor: pointer;
`;

export const StyledIcon = styled.i`
    padding: 0 8px;
`;

export const StyledRow = styled.tr`
    background-color: ${props => (props.index === 0) ? "#F5F5F5" : "white"};
    ${props => props.uploader && css`
        background-color: #ccffe6;
    `}
    ${props => props.even && css`
       background-color: #DCDCDC;
    `}
    margin: auto;
`;

export const StyledRemove = styled.i`
    color: red;
    cursor: pointer;
`;

export const StyledActionButton = styled.button`
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
    background: #3264a8;
    border-radius: 10px;
    padding: 8px 16px;
    margin: 8px;
    color: white;
    font-weight: 700;
    cursor: pointer;
    @media only screen and (max-width: 600px) {
        width: 80%;
    }
    @media only screen and (max-width: 800px) {
        width: 50%;
    }
    @media only screen and (max-width: 1280px) {
        width: 50%;
    }
`;

// Star

export const StyledStar = styled.i`
    font-size: 168px;
    -webkit-text-stroke: 5px #F69D41;
    -webkit-text-fill-color: #357EC0;
    z-index: auto;
    cursor: pointer;

    ${props => props.red && css`
        -webkit-text-fill-color: #ED474B;
    `}

    ${props => props.orange && css`
        -webkit-text-fill-color: #F69D41;
        -webkit-text-stroke: 5px #ED474B;
    `}

`;

export const OverlayText = styled.div`
    width: auto;
    color: white;
    font-size: 18px;
    font-weight: 600;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 10px;
    padding-top: 24px;
    cursor: pointer;
    &:hover {
        text-decoration: underline;
    }
`;

export const RoundedBox = styled.div`
    border-radius: 16px;
    padding: 16px;
    margin: 16px;
    border-color: lightgray;
    border-style: solid;
    overflow: scroll;
`;

export const StyledLink = styled.a`
    text-decoration: underline;
    color: blue;
    ${props => props.subscribe && css`
        color: black;
        text-decoration: none;
        &:hover {
            text-decoration: underline;
        }
    `};
`;

export const ButtonText = styled.div`
    display: inline;
    /* @media screen and (max-width: 800px) {
        display: none;
    } */
`;


const spin = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

export const Spinner = styled.i`
    border: 16px solid #f3f3f3; /* Light grey */
    border-top: 16px solid #3498db; /* Blue */
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: ${spin} 2s linear infinite;
`;

