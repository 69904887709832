import React, { useEffect, useState } from 'react';
import axios from 'axios';

import ClassroomNavBar from '../ClassroomNavBar';
import VideoPlayerContainer from '../VideoPlayerContainer';
import VideoPlaylistContainer from '../VideoPlaylistContainer';
import NotLoggedInPage from '../NotLoggedInPage';

import {
    StyledContainer,
    StyledInlineWrapper,
    StyledLink,
    StyledSubtitle,
    SubContainer,
    VideoContainer,
    Wrapper,
} from './styled';

import {
    useLocation,
  } from "react-router-dom";

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

export default function StudentPortal(props) {
    // Update this to allow video to just redirect to unit_1 OR last place watched

    let query = useQuery();
    const { loggedIn } = props;
    const [isLoading, setLoading] = useState(true)
    const [number, setNumber] = useState(0);
    const [title, setTitle] = useState('Video Title...');
    const [unit, setUnit] = useState(query.get('video'));
    const [description, setDescription] = useState('Video description...')

    // API call to server to get video info
    useEffect(() => {
        getVideoInfo();
    }, [unit])

    function getVideoInfo() {
        if (unit == null ) {
            window.location = '/playvideo?video=unit_1'
        } else {
            axios
            .get(`/api/get_video_details?search_term=${unit}`)
            .then((res) => {
                setNumber(res.data.number)
                setTitle(res.data.title)
                setDescription(res.data.description)
            })
            .then(setLoading(false))
            .catch(err => {
                console.log(err);
                return null;
            });
        }
    };

    function previousVideo() {
        if (number > 1) {
            setUnit(`unit_${number - 1}`)
        }
    }

    function nextVideo() {
        if (number < 12) {
            setUnit(`unit_${number + 1}`)
        }
    }

    function selectVideo(video) {
        // Updates state for when <Link /> in VideoPlaylistItem /> is clicked
        setNumber(video.number)
        setTitle(video.title)
        setUnit(video.id)
        setDescription(video.description)
    }

    function showVideoContainer() {
        if (isLoading) {
            return <div>'LOADING...'</div>
        } return (
            <VideoContainer className="video-container">
                <VideoPlayerContainer search_term={`${unit}.mp4`} />
                <StyledInlineWrapper>
                    <StyledLink
                        to={{
                            path: 'playvideo',
                            search: `video=unit_${number - 1}`
                        }}
                        onClick={previousVideo}
                        hide= {number < 2 ? true : false }
                        style={{padding: '8px'}}
                    >
                        <i style={{color: 'black'}} class="fas fa-backward"></i>
                        <div style={{color: 'black'}} >previous</div>
                    </StyledLink>
                    <StyledSubtitle>{`Video #${number}: ${title}`}</StyledSubtitle>
                    <StyledLink
                        to={{
                            path: 'playvideo',
                            search: `video=unit_${number + 1}`
                        }}
                        onClick= {nextVideo}
                        hide= {number > 11 ? true : false }
                        style={{padding: '8px'}}
                    >
                        <i style={{color: 'black'}} class="fas fa-forward"></i>
                        <div style={{color: 'black'}}>next</div>
                    </StyledLink>
                </StyledInlineWrapper>
                <StyledInlineWrapper><h4 style={{"text-align":'center', margin:'auto'}}>{description}</h4></StyledInlineWrapper>
            </VideoContainer>
        )
    };

    function showVideoPlayer() {
        if (!loggedIn) {
            return (
                <NotLoggedInPage />
            )
        } else {
            return (
                <SubContainer>
                    {showVideoContainer()}
                    <VideoPlaylistContainer
                        selectVideo={selectVideo}
                        loggedIn={loggedIn}
                    />
                </SubContainer>
            )
        }
    }
    return (
        <Wrapper>
            <ClassroomNavBar
                logout={props.logout}
                loggedIn={loggedIn}
            />
            <StyledContainer >
                {showVideoPlayer()}
            </StyledContainer>
        </Wrapper>
    )
}