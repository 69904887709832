import React, { PureComponent } from 'react';

import { Title } from '../SmallComponents';
import { CloseIcon, ModalContent, Wrapper } from './styled';

export default class TCModal extends PureComponent {

    render() {
        const {
            closeModal,
            visible
        } = this.props;
        return(
            <Wrapper visible={visible}>
                <ModalContent>
                    <CloseIcon
                        onClick={closeModal}
                        className="fas fa-times"
                    >
                    </CloseIcon>
                    <Title underlined>
                        EASY, the ESL Series: Terms and Conditions
                    </Title>
                    <h2
                        style={{"text-decoration": "underline", "padding-left": "40px"}}
                    >
                        Introduction
                    </h2>
                    <ol>
                        <li>
                            These Terms and Conditions apply when purchasing, or subscribing to, (hereinafter included as purchasing) the EASY series of online videos, workbooks, teacher guides, apps and other materials (hereinafter collectively referred to as EASY and its provision online as “the Service”) available for purchase by download and streaming, on a nonexclusive basis, at www.easyeld.com and are effective from July 22nd, 2021 until furthernotice or replaced.
                        </li>
                        <li>
                            When you purchase the EASY materials, using an online transaction, you agree to abide by these Terms and Conditions – the study of which was made available to you at the time of purchase and for you to agree as a condition of purchase.
                        </li>
                        <h2
                            style={{"text-decoration": "underline"}}
                        >
                            Copyright
                        </h2>
                        <li>
                            All the EASY material is copyrighted, and the copyright is solely owned by Digital
                            Education Productions (DEP) a company incorporated in the state of California, also
                            doing business as “EASY, the ESL Series” and sometimes referred to as EASY, “Mr.
                            Tandy’s ESL Class”, “EASY ESL” “EASY” and variations thereof.
                        </li>
                        <li>
                            All content available through the Service, including videos, audio, text, graphics, images,
                            designs and styles used, information, software and other files, and their arrangement
                            (the "Service"), are the proprietary property of Digital Education Productions. No Service
                            or part thereof may be modified, copied, distributed, republished, downloaded, scraped,
                            displayed, posted, transmitted, or sold in any form or by any means, in whole or in part,
                            other than as expressly permitted in these Terms and Conditions. You may not use any
                            data mining, robots, scraping or similar data gathering or extraction methods to obtain
                            any part of this Service. As between you and Digital Education Productions, all data,
                            information and materials generated from your access and use of the educational
                            activities made available on or through the Service, including translated content
                            generated by you, shall be exclusively owned by Digital Education Productions, and you
                            shall not have any right to use such material except as expressly authorized by these
                            Terms and Conditions.
                        </li>
                        <li>
                            We have used all reasonable efforts to ensure that all our materials are accurate. We
                            cannot be held responsible for any errors or omissions.
                        </li>
                        <li>
                            The EASY workbooks and teacher guides are delivered as a download of a pdf file
                            version of the workbook or teacher guide. Each page may have your email address
                            included on it. The file you are purchasing is provided as a download from the web, and
                            this transaction is not reversible, therefore all sales are final and we cannot provide a
                            credit.
                        </li>
                        <li>
                            You are permitted to use the EASY downloadable and printable materials in the following methods and environments:
                            <ol type="a">
                                <li>
                                    At your school site, or as an individual.
                                </li>
                                <li>
                                    If at your School site, you are allowed to print them, or parts of them, as many
                                    times as you like for use by your registered students;
                                    If you purchased them as an individual, you are allowed to print them, or parts of
                                    them, as many times as you like for your personal use.
                                </li>
                            </ol>
                        </li>
                        <li>
                        You are explicitly NOT allowed to use the material in the following ways:
                        <ol type="a">
                                <li>
                                You are NOT allowed to edit, or alter in any way, any of the materials.
                                </li>
                                <li>
                                    You are NOT allowed to give, sell, transfer or provide access in any way to
                                    an electronic or hard copy to anyone outside your school site.
                                </li>
                                <li>
                                    You are NOT allowed to email the file to anyone.
                                </li>
                                <li>
                                    You are NOT allowed to upload to the internet any of the files, or copies of
                                    them, whether pdf or videos.
                                </li>
                                <li>
                                    Under no circumstances are you allowed to copy the files or any of the contents and to give, sell, barter or exchange such copies.
                                </li>
                            </ol>
                        </li>
                        <h2
                            style={{"text-decoration": "underline"}}
                        >
                            Data Privacy
                        </h2>
                        <li>
                            If information is requested of visitors to our website then the only data that is requested is: first name, last name, email address, school or organization, country and postcode. No student or teacher created material is requested. We understand that this complies with the GDPR requirements on data privacy. If any visitor to our website wishes to have their information as outlined above, we will remove it within 48 hours of receipt of such a request. This removal may also terminate the subscription.
                        </li>
                        <li>
                            We will never use, nor sell, nor give, any of the personal information you provide to us to any company, organization, individual, etc. We may use the information to send you occasional emails to inform you of news concerning the EASY material.
                        </li>
                        <h2
                            style={{"text-decoration": "underline"}}
                        >
                            Data Charges, subscriptions, service and compatibility
                        </h2>
                        <li>
                            The teachers and students using the Mr. Tandy’s ESL Class App may incur data charges. This will be their responsibility
                        </li>
                        <li>
                            When a subscription is purchased, a code is generated that allows access by students.
                            This code is only valid during the term of the subscription. Student access will terminate
                            when the code expires.
                        </li>
                        <li>
                            Any discount offered is based only on the current purchase transaction. Any discount offered does not refer to any previous purchase nor grouping of purchases.
                        </li>
                        <li>
                            The specifications of equipment running Android and iOS may vary depending of manufacturer, date of purchase, country of use, etc. We make reasonable efforts to
                            support recent versions of phones with these specifications but cannot ensure the full compatibility of all Android and iOS devices.
                        </li>
                        <li>
                            DEP may, in its sole discretion and at any time, update, change, suspend, make improvements to or discontinue any aspect of the Service, temporarily or permanently.
                        </li>
                        <li>
                            If you choose to purchase the option of an auto-renewing periodic subscription, if
                            available, of the Service, your account may be billed periodically for the renewal of your subscription until you terminate it. After your initial subscription period, and again after any subsequent subscription period, your subscription will automatically renew for an additional equivalent period, if the auto-renew option was selected. If you do not wish your subscription to renew automatically do not select this option at time of purchase. If you want to change or terminate your subscription, log in to your account and follow instructions to terminate or change your subscription.
                        </li>
                        <li>
                            In the event that we suspend or terminate your use of the Service under these Terms and Conditions or if you close your account voluntarily, you understand and agree that you will receive no refund or exchange of any kind.
                        </li>
                        <li>
                            “Digital Education Productions” and all other trademarks, service marks, graphics and
                            logos used in connection with the Service are trademarks or service marks of Digital
                            Education Productions, and certain of them may be registered with the United States
                            Patent and Trademark Office. Access and use of the Service does not grant or provide
                            you with the right or license to reproduce or otherwise use the Digital Education
                            Productions name.
                        </li>
                        <h2
                                style={{"text-decoration": "underline"}}
                            >
                                Data Charges, subscriptions, service and compatibility
                            </h2>
                        <li>
                                THE SERVICE, INCLUDING ALL VIDEO, AUDIO AND OTHER CONTENT THEREIN, AND ANY OTHER INFORMATION, PROPERTY AND RIGHTS GRANTED OR
                                PROVIDED TO YOU BY US ARE PROVIDED TO YOU ON AN “AS IS” BASIS. WE MAKE NO REPRESENTATIONS OR WARRANTIES OF ANY KIND WITH RESPECT TO THE SERVICE, EITHER EXPRESS OR IMPLIED, AND ALL SUCH REPRESENTATIONS AND WARRANTIES, INCLUDING WARRANTIES OF MERCHANTABILITY, FITNESS FOR A SPECIFIC PURPOSE ARE EXPRESSLY DISCLAIMED. WITHOUT LIMITING THE GENERALITY OF THE FOREGOING, WE DO NOT MAKE ANY REPRESENTATION OR WARRANTY OF ANY KIND RELATING TO ACCURACY, SERVICE AVAILABILITY, COMPLETENESS, INFORMATIONAL CONTENT, ERROR-FREE OPERATION, RESULTS TO BE OBTAINED FROM USE, OR NON-INFRINGEMENT. ACCESS AND USE OF THE SERVICE MAY BE UNAVAILABLE DURING CERTAIN PERIODS OF PEAK DEMAND AT OUR CONTRACTED FACILITIES AND ALSO AT END-USER SITES, SYSTEM UPGRADES,MALFUNCTIONS OR SCHEDULED OR UNSCHEDULED MAINTENANCE OR FOR OTHER REASONS.
                        </li>
                        <li>
                            We reserve the right to change these Terms and Conditions at any time.
                        </li>
                        <li>
                            If any breach of these Terms and Conditions is discovered, we reserve the right to cancel your account, and any associated student access, without refund and to take legal action against you and or your organization at our sole discretion. In any case, the maximum refund or payment of any kind is limited to the value of the subscription purchased.
                        </li>
                        <li>
                            These terms and conditions are governed by the laws of the United States of America and the laws of the State of California.
                        </li>
                    </ol>
                </ModalContent>
            </Wrapper>
        );
    }
}