import React, { PureComponent } from 'react';
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import FileSaver from 'file-saver';

import NavBar from '../NavBar';
import { Spacer, Text, Title } from '../SmallComponents';

import { Spinner, StyledDiv, Wrapper } from './styled';

class PurchaseDownloadPage extends PureComponent {

    state = {
        loading: true,
    }

    componentDidMount() {
        const { id, user } = queryString.parse(this.props.location.search);
        axios({
            url: `/api/download_purchase?id=${id}&user=${user}`,
            responseType: 'blob',
            method: 'GET'
        })
        .then((res) => {
            const blob = new Blob([res.data], { type: 'application/pdf' });
            let filename = res.headers["x-suggested-filename"];
            blob.name = filename;
            FileSaver.saveAs(res.data, filename);
            this.setState({ loading: false });
            // setTimeout(function(){window.close()}, 5000);
        })
        .catch(err => {
            console.log(err);
            return err.message;
        });
    }

    isLoading = () => {
        const { loading } = this.state;
        if (loading) {
            return(
                <StyledDiv>
                    <Text>Your download is processing.</Text>
                    <Spacer />
                    <Spinner className="fas fa-spinner"></Spinner>
                    <Spacer />
                    <Text>Please wait while your file is created.</Text>
                </StyledDiv>
            )
        }
        return (
            <StyledDiv>
                <Text>Your file has downloaded!</Text>
            </StyledDiv>
        )
    }

    render() {
        return(
            <Wrapper>
                <NavBar />
                <Title>Thank You For Using EASY, the ESL series</Title>
                <Spacer />
                {this.isLoading()}
            </Wrapper>
        )
    }
}

export default withRouter(PurchaseDownloadPage);