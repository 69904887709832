import styled, { keyframes } from 'styled-components';

export const Wrapper = styled.div`
    text-align: center;
    min-height: 760px;
`;

const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const Spinner = styled.i`
    animation: ${rotate360} 1s linear infinite;
    transform: translateZ(0);
    margin: auto;
    font-size: 30px;
`;

export const StyledButton = styled.button`
    margin: auto;
`;

export const StyledDiv = styled.div`
  width: 60%;
  margin: auto;
`;