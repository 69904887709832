import styled from 'styled-components';
import Workbooks from '../Workbooks';

import { StyledIcon, Text, Title } from '../SmallComponents';

export const Wrapper = styled.div`
    text-align: center;
    width: 100%;
    min-height: 760px;
    @media only screen and (max-width: 800px) {
        display: flex;
        flex-direction: column;
    }
`;

export const CartIcon = styled(StyledIcon)`
    cursor: pointer;
    padding-right: 48px;
    padding-left: 8px;
    padding-top: 48px;
    padding-bottom: 16px;
    font-size: 24px;
    @media only screen and (max-width: 800px) {
        padding: 0 0 16px 0;
    }
`;

export const StyledTitle = styled(Title)`
`;

export const IconWrapper = styled.div`
    float: right;
    @media only screen and (max-width: 800px) {
        float: none;
        display: block;
        order: 1;
        padding-bottom: 16px;
    }
`;

export const StyledText = styled(Text)`
    width: 80%;
    margin: auto;
    @media only screen and (max-width: 800px) {
        margin: auto;
        width: 90%;
        order: 2;
    }
`;

export const WorkbooksWrapper = styled.div`
    @media only screen and (max-width: 800px) {
        order: 3;
    }
`;

export const CheckoutText = styled.div`
    padding-right: 32px;
    @media only screen and (max-width: 800px) {
        padding: 0;
    }
`;