import React, { PureComponent } from 'react';

import axios from 'axios';

import { StyledRemove } from '../SmallComponents';

export default class DeleteCustomerButton extends PureComponent {

    handleDeleteCustomer = (event) => {
        event.preventDefault();
        const { salesLeadId, getSalesLeads } = this.props;
        const data = new FormData();
        data.append('customer_id', salesLeadId);
        axios({
            method: 'delete',
            url: '/api/customers',
            data: {
                id: salesLeadId
            }
        })
        .then(() => getSalesLeads())
        .catch(err => {
            console.log(err);
            return null;
        });
    }

    render() {
        return (
            <StyledRemove className="fas fa-trash" add={false} onClick={this.handleDeleteCustomer}></StyledRemove>
        );
    }
}