import styled from 'styled-components';

import { Subtitle } from '../SmallComponents';

export const Wrapper = styled.div`
    width: 80%;
    margin: auto;
`;

export const Title = styled.div`
    size: 24px;
    padding: 8px;
    max-width: 290px;
    margin: auto;
`;

export const Price = styled.div`
    size: 22px;
    padding: 8px;
`;


export const LevelTitle = styled(Subtitle)`
    margin: 16px;
`;

export const WorkbookItem = styled.div`
    min-width: 25%;
    max-width: 50%;
    width: auto;
    padding-top: 16px;
    padding-bottom: 16px;
    margin: auto;
`;

export const LevelWrapper = styled.div`
    display: flex;
    margin: auto;
    flex-wrap: wrap;
`;