import React from 'react';

import { StyledContainer, Wrapper } from './styled';

import VideoPlaylistItem from '../VideoPlaylistItem';

export default function VideoPlaylistComponent(props) {

    const height = window.innerHeight - 200;

    function displayPlaylistItems() {
        const { videoList } = props;
        if (videoList.length) {
            const playlistItems = videoList.map(video => (
                <VideoPlaylistItem video={video} {...props} />
          ))
          return playlistItems
        }
        return "Loading playlist"
    }

    return (
        <StyledContainer className="video-playlist-container">
            <h3>Mr. Tandy's ESL Videos</h3>
            <Wrapper height={height}>
                {displayPlaylistItems()}
            </Wrapper>
        </StyledContainer>
    )
}