import React, { PureComponent } from 'react';

import { OverlayText, StyledStar } from '../SmallComponents';

import { Wrapper } from './styled';

import { downloadFile } from '../helperfunctions.js'

export default class SnSStar extends PureComponent {

    // handleClick = () => {
    //     downloadFile('EASY Scope and Sequence.pdf');
    // }

    render() {
        return(
            <Wrapper>
                <a href="/ScopeAndSequence.pdf">
                    <OverlayText> Download <br />the EASY<br />Scope &<br />Sequence</OverlayText>
                    <StyledStar className="fas fa-star"></StyledStar>
                </a>
            </Wrapper>
        );
    }
};