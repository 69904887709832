import React, { PureComponent } from 'react';

import { ButtonText, StyledActionButton, StyledIcon } from '../SmallComponents';

export default class DownloadButton extends PureComponent {

    handleClick = () => {
        const { downloadSample, worksheet } = this.props;
        downloadSample(worksheet);
    }

    render() {

        return (
            <StyledActionButton onClick={this.handleClick}>
                <StyledIcon className="fas fa-file-download"></StyledIcon>
                <ButtonText>Download Worksheet</ButtonText>
            </StyledActionButton>
        );
    }
}
