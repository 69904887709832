import React, { PureComponent } from 'react';

import { Text, Title } from '../SmallComponents';
import { CloseIcon, ModalContent, Wrapper } from './styled';

export default class CCErrorModal extends PureComponent {

    render() {
        const {
            closeModal,
            visible
        } = this.props;
        return(
            <Wrapper visible={visible}>
                <ModalContent>
                    <CloseIcon
                        onClick={closeModal}
                        className="fas fa-times"
                    >
                    </CloseIcon>
                    <Title>Payment Failed</Title>
                    <Text>Payment failed. Please try entering your card information again.</Text>
                </ModalContent>
            </Wrapper>
        );
    }
}