import styled from 'styled-components';

import { StyledIcon } from '../SmallComponents';

export const Wrapper = styled.div`
    /* border-style: dotted;
    border-color: black;
    border-radius: 8px;
    padding: 8px;
    width: 25%;
    margin: 16px auto; */
    display: ${props => props.visible ? "block;" : "none;" };
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
`;

export const ModalContent = styled.div`
    padding: 50px 0;
    background-color: #fefefe;
    margin: 100px auto; /* 15% from the top and centered */
    padding: 36px;
    border: 1px solid #888;
    border-radius: 20px;
    width: 60%; /* Could be more or less, depending on screen size */
    overflow: scroll;
    /* display: flex; */
`;

export const CloseIcon = styled(StyledIcon)`
    float: right;
`;