import React from 'react';
import './App.css';
import { Helmet } from 'react-helmet';
import CookieConsent from "react-cookie-consent";

import MainPage from './MainPage';

function App() {
  return (
    <div>
      <Helmet>
        <title>EASY, the ESL Series</title>
        <meta charSet="utf-8" />
        <meta name="description" content='EASY the ESL Series" is a video-based ESL system for teenagers and adults who want to learn real-world American English and have fun as they learn.' />
        <link rel="canonical" href="https://easyeld.com" />
      </Helmet>
      <CookieConsent
        location="bottom"
      >
        This website uses cookies to enhance the user experience.
      </CookieConsent>
      <MainPage />
    </div>
  );
}

export default App;
