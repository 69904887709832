import styled from 'styled-components';

import {
    Link,
} from "react-router-dom";

export const VideoPic = styled.img`
    width: 100%;
    border-radius: 16px;
    @media screen and (min-width: 800px) {
        padding: 8px;
    }
`;

export const PicWrapper = styled.div`
    width: 60%;
    padding: 16px;
    @media screen and (max-width: 800px) {
        padding: 8px;
        width: 20%;
    }
    @media screen and (max-width: 480px) {
        min-width: 50%;
    }
`;

export const DescriptionWrapper = styled.div`
    text-align: left;
    width: 100%;
    padding: 8px;
    @media screen and (max-width: 800px) {
        width: 50%;
    }
`;

export const OuterWrapper = styled.div`
    background-color: #ffffff;
    background-image: linear-gradient(315deg, #ffffff 0%, #d7e1ec 74%);
    border-color: white;
    border-width: 4px;
    border-radius: 8px;
    box-shadow: 5px 5px 5px gray;
    margin-top: 8px;
    margin-bottom: 8px;
    display: flex;
    @media screen and (max-width: 800px) {
        width: 95%;
        margin-left: auto;
        margin-right: auto;
   }
    @media screen and (max-width: 480px) {
        width: 80%;
        padding: 16px;
        margin-left: auto;
        margin-right: auto;

   }
`;

export const StyledLink = styled(Link)`
    text-decoration: none;
    color: black;
    font-weight: 600;
    &:hover {
        cursor: pointer;
    }
`;

export const BigCardText = styled.div`
    width: 100%;
    display: none;
    @media screen and (max-width: 480px) {
        display: block;
   }
`;

export const SmallCardText = styled.div`
    max-width: 100%;
    display: block;
    @media screen and (max-width: 480px) {
        display: none;
   }
`;