import React, { PureComponent } from 'react';

import axios from 'axios';

import { StyledRemove } from '../SmallComponents';

export default class DeleteWorkbook extends PureComponent {

    handleDeleteWorkbook = (event) => {
        event.preventDefault();
        const { workbook, getWorkbooks } = this.props;
        axios({
            method: 'delete',
            url: '/api/workbooks',
            data: {
                id: workbook.id,
                file_name: workbook.file_name
            }
        })
        .then(() => getWorkbooks())
        .catch(err => {
            console.log(err);
            return null;
        });
    }

    render() {
        return (
            <StyledRemove className="fas fa-trash" add={false} onClick={this.handleDeleteWorkbook}></StyledRemove>
        );
    }
}