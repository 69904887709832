import styled, { css } from 'styled-components';
import { Text } from '../SmallComponents';

export const ContentWrapper = styled.div`
    width: 80%;
    margin: auto;
    text-align: center;
    min-height: 760px;
    @media screen and (max-width: 800px) {
        width: 95%;
    }
`;

export const StyledSpan = styled.span`
    padding: 8px;
`;

export const StyledLi = styled.li`
 list-style: none;
`;

const StyledInput = styled.input`
    margin: 8px;
    display: block;
`;

export const FormInput = styled(StyledInput)`
    margin: 0;
    flex-grow: 2;
    padding-right: 8px;
    ${props => props.stacked && css`
        margin: 0 8px;
    `}
    @media screen and (max-width: 800px) {
        padding: 0;
    }
`;

export const OrganizationName = styled(StyledInput)`
    margin-left: 8px;
`;

export const HelperText = styled.div`
    background-color: lightyellow;
    padding: 4px;
    border-radius: 4px;
    font-size: 10px;
    display: block;
`;

export const POInput = styled(StyledInput)`
`;

export const EmailInput = styled(StyledInput)`
    margin-left: 8px;
`;

export const NameWrapper = styled.div`
    display: flex;
    margin: auto;
    padding: 16px 0 16px 0;
    max-width: 45%;
    flex-wrap: wrap;
    ${props => props.stacked && css`
        flex-direction: column;
    `}
    ${props => props.centered && css`
        text-align: center;
    `}
`;

export const AddressWrapper = styled(NameWrapper)`
    width: 50%;
`;

export const NameLabel = styled.label`
`;

export const StyledTable = styled.table`
    margin: auto;
`;

export const StyledTh = styled.th`
    padding: 16px;
`;

export const StyledText = styled.div`
    margin: auto;
    padding: 16px;
    font-size: 18px;
    display: inline-block;
`;

export const BoldText = styled.div`
    font-weight: 700;
    display: inline-block;
`;

export const StyledLabel = styled.label`
    padding-right: 8px;
    ${props => (props.stacked || props.centered) && css`
        margin: auto;
    `}
`;

export const ErrorAlert = styled.div`
    display: ${props => props.error ? "block" : "none"};
    color: red;
    width: 100%;
`;