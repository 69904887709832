import React from 'react';
import {useStripe, useElements, CardElement} from '@stripe/react-stripe-js';

import { Subtitle } from '../SmallComponents'

import { SmallText, StyledButton, StyledLabel, StyledRoundedBox } from './styled';

const CARD_ELEMENT_OPTIONS = {
    style: {
      base: {
        backgroundColor: "#f9f9f9",
        color: "#32325d",
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#aab7c4",
        },
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    },
  };


export default function CheckoutBox(props) {

  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event) => {
    event.preventDefault();
    const allBoxesFilled = props.boxValidation();
    if (!allBoxesFilled) {
      alert('Please fill out all required boxes. Ensure emails match.')
      return
    }
    const disableStripeButton = props.disableStripeButton;
    const updatePurchaseRecord = props.updatePurchaseRecord;
    // Call database & update with form information
    disableStripeButton();
    updatePurchaseRecord();
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    const result = await stripe.confirmCardPayment(props.clientSecret, {
      payment_method: {
        card: elements.getElement(CardElement),
        billing_details: {
          email: props.email,
        },
      }
    });

    if (result.error) {
      // Show error to your customer (e.g., insufficient funds)
      const showErrorModal = props.showErrorModal;
      showErrorModal();
    } else {
      // The payment has been processed!
      if (result.paymentIntent.status === 'succeeded') {
        const redirectState = props.redirectState;
        redirectState();
        // Show a success message to your customer
        // There's a risk of the customer closing the window before callback
        // execution. Set up a webhook or plugin to listen for the
        // payment_intent.succeeded event that handles any business critical
        // post-payment actions.
      }
    }
  };

  return (
    <div>
        <StyledLabel>
            <Subtitle>Card details</Subtitle>
            <StyledRoundedBox>
              <CardElement options={CARD_ELEMENT_OPTIONS} />
              <SmallText>Secure credit card processing through <a href="https://stripe.com/">Stripe.</a></SmallText>
            </StyledRoundedBox>
        </StyledLabel>
      <StyledButton disabled={!stripe || props.stripeButtonDisabled} onClick={handleSubmit}>Confirm order</StyledButton>
    </div>
  );
}