import React, { PureComponent } from 'react';

import { OverlayText, StyledStar } from '../SmallComponents';

import { Wrapper } from './styled';

export default class PurchaseStar extends PureComponent {

    render() {
        return(
            <Wrapper>
                <a href="/shop">
                    <OverlayText>
                        Purchase <br />Workbooks<br /><br />
                    </OverlayText>
                    <StyledStar orange className="fas fa-star"></StyledStar>
                </a>
            </Wrapper>
        );
    }
};