import styled from 'styled-components';

export const Wrapper = styled.div`
    text-align: center;
    margin-bottom: 32px;
    padding-bottom: 32px;
`;

export const StyledButton = styled.button`
    background: #F69D41;
`;
