import React, { useRef, useEffect } from 'react';
import videojs from "video.js";

import VideoContextMenu from '../VideoContextMenu';
import "video.js/dist/video-js.css"

import { StyledContainer } from './styled';

export default function VideoPlayerComponent(props) {
    const outerRef = useRef(null);
    const videoRef = React.useRef(null);

    const options = {
        aspectRatio: '4:3',
        controls: true,
        responsive: true,
        fluid: true,
        sources:[{
            src: props.url,
            type: 'video/mp4'
        }]
    }

    const VideoHtml = ( props ) => (
        <div data-vjs-player>
            <video
                src={props.url}
                ref={videoRef}
                className="video-js vjs-fluid vjs-big-play-centered vjs-default-skin"
                style={{  "border-radius": "5px",
                        "box-shadow": "5px 5px 5px rgba(0, 0, 0, 0.5)"
                }}
            />
        </div>
    );

    useEffect(() => {
        const videoElement = videoRef.current;
        let player;
        if (videoElement) {
            player = videojs(videoElement, options, () => {
            });
        }
        return () => {
            if( player ) {
                player.dispose();
            }
        }
    }, [options])

    return (
        <StyledContainer>
            <div style={{margin: "auto",width:"95%"}}>
                <VideoContextMenu outerRef={outerRef} />
                <VideoHtml />
            </div>
        </StyledContainer>
    )
}

