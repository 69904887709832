import styled from 'styled-components';

import { Text } from '../SmallComponents';

export const TextWrapper = styled.div`
    display: flex;
    margin: auto;
    @media screen and (max-width: 800px) {
        flex-direction: column;
        width: 100%;
    }
`;

export const StarWrapper = styled.div`
    text-align: center;
    display: flex;
    margin: auto;
    width: 50%;
    @media screen and (max-width: 800px) {
        order: 1;
        width: 90%;
        margin: auto;
        flex-direction: column;
    }
`;

export const StyledText = styled(Text) `
    width: 90%;
    margin: auto;
    margin-top: 24px;
    @media screen and (max-width: 800px) {
        margin-bottom: 16px;
        order: 0;
        width: 90%;
    }
`;