import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
    text-align: center;
    margin-bottom: 32px;
    padding-bottom: 32px;
    position: relative;
`;

export const StyledButton = styled.button`
    background: #F69D41;
`;

export const StyledTable = styled.table`
    width: 100%;
    margin: auto;
    border: 1px solid #DCDCDC;
    border-spacing: 0;
`;

export const TableHead = styled.thead`
    /* position:absolute; */
    /* z-index: 2;
    top: -20px; */
`;

export const TableScroll = styled.div`
    overflow: auto;
    height: 350px;
`;

export const TableWrapper = styled.div`
    height: 500px;
    overflow: scroll;
    border: 1px solid #DCDCDC;
    width: 80%;
    margin: auto;
`;

export const StyledTd = styled.td`
    border: hidden;
`;

export const StyledUl = styled.ul`

`;

export const TableBody = styled.div`
    overflow: scroll;
`;