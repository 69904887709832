import styled, { css } from 'styled-components';

export const TableSelector = styled.div`
    text-align: center;
    background: #A7A7A7;
    width: 80%;
    margin: 16px auto;
    border-radius: 10px;
`;

export const SelectItem = styled.div`
    width: 30%;
    margin: auto;
    display: inline-block;
    padding: 16px;
    font-weight: 500;
    cursor: pointer;
    ${props => props.highlighted && css`
        background: #DADADA;
    `}
`;

export const ColorBlock = styled.div`
    background-color: ${props => props.workbook ? "#F6F68F" : "#C5FDC5" };
    width: 80%;
    margin: auto;
    padding: 24px;
`;

export const StyledInput = styled.input`
    padding: 4px;
    height: 20px;
    font-size: 18px;
    width: 50%;
    margin-bottom: 8px;
`

export const StyledTextArea = styled.textarea`
    width: 80%;
    height: 36px;
    font-size: 18px;
    margin-bottom: 8px;
`

export const SmallStyledInput = styled(StyledInput)`
    width: 25%;
`;