import styled from 'styled-components';

export const Wrapper = styled.div`
    display: ${props => props.visible ? "block;" : "none;" };
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
`;

export const ModalContent = styled.div`
    padding: 50px 0;
    background-color: #fefefe;
    margin: 100px auto; /* 15% from the top and centered */
    padding: 36px;
    border: 1px solid #888;
    border-radius: 20px;
    width: 30%; /* Could be more or less, depending on screen size */
    display: inline-block;
`;

export const MessageDiv = styled.div`
    background: lightgrey;
    margin: 8px;
    padding: 8px;
`;

export const LabelWrapper = styled.div`
    width: 40%;
    text-align: right;
    display: inline-block;
    margin-right: 8px;
`;

export const StyledForm = styled.form`
    text-align: left;
`;