import styled from 'styled-components';

import { StyledIcon } from '../SmallComponents';

export const StyledCell = styled.td`
    width: 25%;
`;

export const RemoveIcon = styled(StyledIcon)`
    color: red;
`;