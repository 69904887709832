import React, { PureComponent } from 'react';

import { Spacer, Title } from '../SmallComponents';

import { LabelWrapper, MessageDiv, ModalContent, StyledForm, Wrapper } from './styled';

export default class RegisterNewUserModal extends PureComponent {

    state = {
        username: '',
        password: '',
        password2: '',
        email: '',
    };

    handleNameFieldChange = (event) => {
        this.setState({ username: event.target.value });
    };

    handlePasswordFieldChange = (event) => {
        this.setState({ password: event.target.value });
    };

    handlePassword2FieldChange = (event) => {
        this.setState({ password2: event.target.value });
    };

    handleEmailFieldChange = (event) => {
        this.setState({ email: event.target.value });
    };

    handleClick = () => {
        const { closeModal, registerNewAdmin } = this.props;
        const { username, email, password, password2 } = this.state;
        const data = {
            username,
            email,
            password
        };
        if (password === password2 ) {
            registerNewAdmin(data);
            closeModal();
            document.getElementById('new-user-form').reset();
            this.setState({ username: '', email: '', password: '' });
            return
        }
        return alert("Passwords do not match.")

    };

    render() {
        const { visible, closeModal } = this.props;

        return(
            <Wrapper visible={visible}>
                <ModalContent>
                    <Title>Register New Admin</Title>
                         <StyledForm id="new-user-form">
                            <LabelWrapper>
                                <label for="username">Username: </label>
                            </LabelWrapper>
                            <input id="username" type="text" value={this.state.username} onChange={this.handleNameFieldChange}  required />
                            <Spacer />
                            <LabelWrapper>
                            <label for="email">Email: </label>
                            </LabelWrapper>
                            <input id="email" type="email" required value={this.state.email} onChange={this.handleEmailFieldChange} />
                            <Spacer />
                            <LabelWrapper>
                                <label for="password">Password: </label>
                            </LabelWrapper>
                            <input
                                id="password"
                                onChange={this.handlePasswordFieldChange}
                                pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                                required title="Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters"
                                type="password"
                                value={this.state.password}
                            />
                            <Spacer />
                            <LabelWrapper>
                                <label for="password-2">Type Password Again: </label>
                            </LabelWrapper>
                            <input
                                id="password-2" type="password"
                                onChange={this.handlePassword2FieldChange}
                                required
                                value={this.state.password2}
                            />
                        </StyledForm>
                    <br />
                    <MessageDiv>
                        <h3>Password must contain the following:</h3>
                        <p id="letter" class="invalid">A <b>lowercase</b> letter</p>
                        <p id="capital" class="invalid">A <b>capital (uppercase)</b> letter</p>
                        <p id="number" class="invalid">A <b>number</b></p>
                        <p id="length" class="invalid">Minimum <b>8 characters</b></p>
                    </MessageDiv>
                    <Spacer />
                    <button onClick={this.handleClick}>
                        Register New Admin
                    </button>
                    <Spacer />
                    <button onClick={closeModal}>Cancel</button>
                </ModalContent>
            </Wrapper>
        )
    }
}