import React from 'react';

import { Button } from '@material-ui/core';

export default function NotLoggedInPage() {
    return(
        <div style={{ display: 'flex', 'justify-content': 'center',  'align-items': 'center', height: '200px', 'flex-wrap': 'wrap' }}>
            <div style={{ width: "100%"}}>
                Please login or purchase a subscription to continue.
            </div>
            <div style={{width: '100%'}}>
                <a href="/login">
                    <Button color="primary" variant="contained">
                        Login
                    </Button>
                </a>
            </div>
            <div style={{width: '100%'}}>
                <a href="/shop">
                    <Button color="secondary" variant="contained">
                        Shop
                    </Button>
                </a>
            </div>
        </div>
    )
}