import styled from 'styled-components';

export const LogoWrapper = styled.div`
    text-align: left;
    width: 20%;
    margin: 0;
    display: inline-block;
    @media screen and (max-width: 480px) {
        width: 70%;
    }
    @media screen and (max-width: 800px) {
        width: 50%;
    }

`;

export const Logo = styled.img`
    margin-top: 8px;
    margin-left: 8px;
    background: white;
    border-radius: 8px;
    @media screen and (max-width: 1024px) {
        margin: 24px 0 16px 24px;
        width: 100%;
    }
`;