import React, { PureComponent } from 'react';

import logo from '../easylogoB.png';

import { LogoWrapper, Logo } from './styled';

export default class MainPage extends PureComponent {
    render() {
        return (
            <LogoWrapper>
                <a href="/">
                    <Logo
                        alt="Easy - Your English Classroom"
                        className="easy-logo-b"
                        src={logo}
                    />
                </a>
            </LogoWrapper>
        );
    }
};