import React, { PureComponent } from 'react';
import { withRouter} from 'react-router-dom';

import NavBar from '../NavBar';
import { Spacer, StyledRow, Subtitle, Text, Title } from '../SmallComponents';

import { BoldText, PurchaseTable, StyledButton, StyledTd, Wrapper } from './styled';

class ThankYouPage extends PureComponent {

    componentDidMount() {
        this.clearCart();
    }

    clearCart = () => {
        localStorage.removeItem("EasyCart");
    }

    displayPurchasedItems = () => {
        const { cartItems } = this.props.location.state;
        if (cartItems) {
            const items = cartItems.map(item => (
                <StyledRow>
                    <StyledTd>{item.name}</StyledTd>
                    <StyledTd>${item.price}</StyledTd>
                </StyledRow>
            ))
            return items
          }
    }

    handleClick = () => {
        window.print();
    }

    render() {

        const { cartTotal, email, orderNumber, poNumber } = this.props.location.state;

        return(
            <Wrapper>
                <NavBar />
                <Title>
                    Thank you for your order
                </Title>
                <Text><BoldText>EASY Order #: </BoldText>{orderNumber}</Text>
                <br />
                {poNumber && <Text><BoldText>Your purchase order reference #: </BoldText>{poNumber}</Text>}
                <br />
                <Subtitle>Your Order Details</Subtitle>
                <PurchaseTable>
                    {this.displayPurchasedItems()}
                </PurchaseTable>
                <Spacer />
                <Text><BoldText>Total: </BoldText>${cartTotal}</Text>
                <br />
                <Text><BoldText>Payment method: </BoldText>Credit Card</Text>
                <Spacer />
                <Text>Your order & receipt has been sent to {email}.</Text>
                <br />
                <Text>Please print this page for your records.</Text>
                <Spacer />
                <StyledButton onClick={this.handleClick}>Print page</StyledButton>
            </Wrapper>
        )
    }
}

export default withRouter(ThankYouPage);