import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import {
    Spacer,
    StyledInput,
    StyledLabel,
} from '../SmallComponents';

import {
    ButtonWrapper,
    CenteringWrapper,
    Modal,
    ModalContent,
    ModalHeader,
    NoThanks,
    StyledForm,
    SubmitButton,
} from './styled';

export default class CallToAction extends PureComponent {

    state = {
        address: '',
        email: '',
        name: '',
        organization: '',
        comment: '',
    };

    handleSubmit = (e) => {
        e.preventDefault();
        const { name, email, organization, address, comment } = this.state;
        const { buttonClick } = this.props;
        const data = new FormData();
        data.append('name', name);
        data.append('email', email);
        data.append('institution_name', organization);
        data.append('address', address);
        data.append('comment', comment)
        buttonClick(data);
    }

    handleNameChange = (event) => {
        this.setState({ name: event.target.value });
    }

    handleEmailChange = (event) => {
        this.setState({ email: event.target.value });
    }

    handleAddressChange = (event) => {
        this.setState({ address: event.target.value });
    }

    handleOrganizationChange = (event) => {
        this.setState({ organization: event.target.value });
    }

    handleCommentChange = (event) => {
        this.setState({ comment: event.target.value });
    }

    handleNoThanks = () => {
        const { closeModal, setRedirect } = this.props;
        closeModal();
        setRedirect();
    }

    render() {
        const { visible } = this.props;
        return (
            <Modal visible={visible}>
                <ModalContent>
                    <CenteringWrapper>
                        <ModalHeader>
                        Thank you for downloading some of the EASY sample kits. Please complete this form to continue.
                        </ModalHeader>
                    </CenteringWrapper>
                    <StyledForm onSubmit={this.handleSubmit}>
                        <Spacer />
                        <StyledLabel>
                            Name:<br /> <StyledInput type="text" name="name" value={this.state.name} onChange={this.handleNameChange} required />
                        </StyledLabel>
                        <br />
                        <StyledLabel>
                            Email:<br /> <StyledInput type="email" name="email" value={this.state.email} onChange={this.handleEmailChange} required />
                        </StyledLabel>
                        <br />
                        <StyledLabel center>
                            College/School/Company Name:<br />
                        <StyledInput
                            type="text" name="organization" value={this.state.organization} onChange={this.handleOrganizationChange} required
                        />
                        </StyledLabel>
                        <br />
                        <StyledLabel center>
                            Address:<br />
                        <StyledInput
                            type="text" name="address" value={this.state.address} onChange={this.handleAddressChange} required
                        />
                        </StyledLabel>
                        <br />
                        <StyledLabel center>
                            Comment:<br />
                        <StyledInput
                            type="text" name="comment" value={this.state.comment} onChange={this.handleCommentChange}
                            maxlength="250"
                        />
                        </StyledLabel>
                        <Spacer />
                        <ButtonWrapper>
                            <SubmitButton type="submit" value="Submit" />
                            <NoThanks onClick={this.handleNoThanks}>No Thanks!</NoThanks>
                        </ButtonWrapper>
                    </StyledForm>
                    <Spacer />
                </ModalContent>
            </Modal>

        )
    }
}

CallToAction.propTypes = {
    buttonClick: PropTypes.func,
    visible: PropTypes.bool,
};