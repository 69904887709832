import React, { PureComponent } from 'react';

import { StyledIcon } from '../SmallComponents';

import { CenteringWrapper, ColorBar, Hamburger,  MobileNavBar, NavBarWrapper, NavItem, NavItemWrapper, StyledLogo  } from './styled';

const navArray = [{ name: 'Samples', route: '/', icon: "fas fa-home", color: '#357EC0'}, { name: 'About', route: '/about', icon: "fas fa-pencil-alt", color: '#F69D41' }, { name: 'Purchase Workbooks', route: '/shop', icon: "fas fa-book", color: '#ED474B'} ];

// const hiddenRoutes =[{ name: 'Checkout', route: '/checkout', color: '#ED474B' }, { name: 'Login', route: '/login', color: '#4D9B57' }, { name: 'Admin', route: 'admin', color: '#FBBD03'}]

export default class NavBar extends PureComponent {

    state = {
        showMobileNavBar: false,
        path: window.location.pathname,
    };

    componentDidMount() {
        this.identityPath();
    }

    handleClick = () => {
        const { showMobileNavBar } = this.state;
        this.setState({ showMobileNavBar: !showMobileNavBar });
    }

    identityPath = () => {
        const path = window.location.pathname;
        const color = this.matchColor();
        this.setState({ path, color });
    }

    matchColor = () => {
        const { path } = this.state;
        // Loop through navArray, find route that matches path, set color as state
        for (const item of navArray) {
            if (item.route === path) {
              return item.color
            }
        }
    }

    displayNavItems = () => {
        const { admin } = this.props;
        const { path } = this.state;
        const items = navArray.map(item => (
            <a href={item.route}>
                <CenteringWrapper
                    color={item.color}
                    widthCalc={navArray.length}
                    outline={path !== item.route}
                    admin={admin}
                >
                    <NavItem
                        className="nav-item"
                    >
                        <StyledIcon className={item.icon} />
                        {item.name}
                    </NavItem>
                </CenteringWrapper>
            </a>
        ))
        return items
    }

    displayAdminItem = () => {
        const { admin, logOut } = this.props;
        if (admin) {
            return (
                <NavItem href="/login" admin={admin} widthCalc={navArray.length} className="nav-item" onClick={logOut}>
                        <StyledIcon nav className="fas fa-sign-out-alt" />
                        Logout
                </NavItem>
            )
        }
    }

    render() {
        const { showMobileNavBar, color } = this.state;
        return(
            <div>
                <NavBarWrapper>
                    <StyledLogo />
                    <NavItemWrapper className="nav-item-wrapper">
                        {this.displayNavItems()}
                        {this.displayAdminItem()}
                    </NavItemWrapper>
                    <Hamburger
                        className="fas fa-bars"
                        onClick={this.handleClick}
                    ></Hamburger>
                </NavBarWrapper>
                <ColorBar color={color}/>
                <MobileNavBar visible={showMobileNavBar}>
                    {this.displayNavItems()}
                    {this.displayAdminItem()}
                </MobileNavBar>
            </div>
        );
    }
}