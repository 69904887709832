import React, { PureComponent } from 'react';

import DogImage from './have_you_heard_dog.png';
import SampleStar from '../SampleStar';
import SnSStar from '../SnSStar';

import { DogPhoto, PhotoWrapper, StarWrapper } from './styled';

export default class Dog extends PureComponent {
    render() {
        return (
            <PhotoWrapper>
                <DogPhoto
                    src={DogImage}
                    className="have-you-heard"
                    alt="Have you heard about EASY ESL? The best English courses- for school, at home, anywhere"
                />
                <StarWrapper>
                    <SnSStar />
                    <SampleStar />
                </StarWrapper>
            </PhotoWrapper>
        )
    }
}
